export function separateThousandsMonetary(number) {
  if (typeof number !== "number") {
    throw new Error("L'entrée doit être un nombre");
  }

  const formattedNumber = number
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedNumber;
}
export function separateThousandsMonetaryEuros(number) {
  if (typeof number !== "number") {
    throw new Error("L'entrée doit être un nombre");
  }

  const formattedNumber = number
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedNumber;
}
