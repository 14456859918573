import axios from "axios";
import "../axios";

export const addEpisode = async (form) => {
  let data = await axios.post("/episodes", form);
  return data;
};

export const getAllEpisodes = async () => {
  let data = await axios.get("/episodes");
  return data;
};

export const updateEpisode = async (id, form) => {
  let data = await axios.patch(`/episodes/${id}`, form);
  return data;
};

export const getEpisodeInfo = async (id) => {
  let data = await axios.get(`/episodes/${id}`);
  return data;
};
export const deleteEpisode = async (id) => {
  let data = await axios.delete(`/episodes/${id}`);
  return data;
};
