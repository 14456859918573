import React from "react";

import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TitleComponent from "../../shared/TitleComponent";
import StatsComponent from "./components/StatsComponent";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import { Line } from "react-chartjs-2";
import {
  separateThousandsMonetary,
  separateThousandsMonetaryEuros,
} from "../../utils/separateThousand";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { getPaiementsToday, getStatsPaiement } from "../../api/paiementsApi";
import { getClientsToday } from "../../api/clientsApi";
import { useState } from "react";
import StatsPaiements from "./components/StatsPaiements";
import { getDataHeader } from "../../api/statsApi";
import Loader from "../../components/shared/Loader";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUserAdmin } from "../../redux/store/authStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  const currentUser = useSelector(selectCurrentUser);

  const [dataEuro, setDataEuro] = useState([]);
  const [dataCfa, setDataCfa] = useState([]);
  const [dataGnf, setDataGnf] = useState([]);
  const [countDevise, setCountDevise] = useState([]);
  const [statsHeader, setStatsHeader] = useState([]);
  const [loading, setLoading] = useState(false);

  const [todayData, setTodayData] = useState();
  const [todayPaiement, setTodayPaiement] = useState();
  const [isOpen, setIsOpen] = useState(true);

  const dispatch = useDispatch();

  const chartHeight = window.innerWidth < 600 ? 250 : 150;

  const getGraph = async () => {
    let count = [];
    setLoading(true);
    await getStatsPaiement(currentUser.id)
      .then((res) => {
        setDataEuro(res.data.euro);
        setDataCfa(res.data.cfa);
        setDataGnf(res.data.gnf);

        count = [
          {
            id: 1,
            name: "Paiements en Euro",
            stat: res.data.totalEuro,
            currency: "EUR",
          },
          {
            id: 2,
            name: "Paiements en GNF",
            stat: res.data.totalGnf,
            currency: "GNF",
          },
          {
            id: 3,
            name: "Paiements en CFA",
            stat: res.data.totalCfa,
            currency: "CFA",
          },
        ];
        setCountDevise(count);
        setLoading(false);
      })
      .catch((err) => console.log("err", err));
  };

  const getStatsHead = async () => {
    await getDataHeader(currentUser.id)
      .then((res) => {
        setStatsHeader(res.data);
        console.log("statsHeader:", res.data);
        res.data.forEach((item, index) => {
          console.log("item", item);
        });
      })
      .catch((err) => console.log("err", err));

    await getClientsToday().then((res) => {
      setTodayData(res.data);
      console.log("res.data", res.data);
    });
    await getPaiementsToday().then((res) => {
      setTodayPaiement(res.data);
      console.log("res.data", res.data);
    });
  };

  useEffect(() => {
    getGraph();
    getStatsHead();
  }, []);

  const labelsEuro = dataEuro?.map((data) => data.date);
  const labelsCfa = dataCfa?.map((data) => data.date);
  const labelsGnf = dataGnf?.map((data) => data.date);
  const dataPointsEuro = dataEuro?.map((data) => data.total);
  const dataPointsCfa = dataCfa?.map((data) => data.total);
  const dataPointsGnf = dataGnf?.map((data) => data.total);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        // text: "Statistique des paiements tout confondus",
      },
    },
  };

  const chartDataEuro = {
    labels: labelsEuro,
    datasets: [
      {
        label: "Euros",
        data: dataPointsEuro,
        fill: true,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.3,
      },
    ],
  };

  const chartDataCfa = {
    labels: labelsCfa,
    datasets: [
      {
        label: "CFA",
        data: dataPointsCfa,
        fill: true,
        borderColor: "rgb(217, 169, 58)",
        tension: 0.3,
      },
    ],
  };

  const chartDataGnf = {
    labels: labelsGnf,
    datasets: [
      {
        label: "GNF",
        data: dataPointsGnf,
        fill: true,
        borderColor: "rgb(215, 30, 89)",
        tension: 0.3,
      },
    ],
  };

  const data = {
    labels: labelsCfa,
    datasets: [
      {
        label: "Euros",
        data: dataPointsEuro,
        fill: true,
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgb(75, 192, 192)",
        tension: 0.3,
      },
      {
        label: "CFA",
        data: dataCfa?.map((data) => data.total),
        fill: true,
        borderColor: "rgb(217, 169, 58)",
        backgroundColor: "rgb(217, 169, 58)",
        tension: 0.3,
      },
      {
        label: "GNF",
        data: dataPointsGnf,
        fill: true,
        borderColor: "rgb(215, 30, 89)",
        backgroundColor: "rgb(215, 30, 89)",
        tension: 0.3,
      },
    ],
  };

  function toggleCollapse() {
    setIsOpen(!isOpen);
  }

  return (
    <TemplateHeaderContainter
      title={`Welcome ${currentUser.firstName}`}
      description=""
      id={currentUser.id}
    >
      <StatsComponent
        stats={statsHeader}
        todayData={todayData}
        todayPaiement={todayPaiement}
        currentUser={currentUser}
        title={"Total des Utilisateurs"}
        colorIcon={"text-orange-500"}
      />

      <StatsPaiements
        stats={countDevise}
        title={"Total des Paiements"}
        colorIcon={"text-cyan-600"}
      />

      <div id="paiement" className="mt-0 px-5 py-3 lg:m-2 rounded-lg shadow-md">
        {loading ? (
          <Loader />
        ) : (
          <>
            <h3 className="text-lg font-medium leading-6 text-gray-90 ml-5">
              Statistiques des Paiements
            </h3>
            <div className="grid grid-rows md:grid-cols-2 lg:grid-cols-3">
              <div
                id="paiementGNF"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2  border-red-500 shadow-red-400/50 lg:first:col-span-1"
              >
                <Line data={chartDataGnf} options={chartOptions} />
              </div>
              <div
                id="paiementEuro"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2 border-teal-500"
              >
                <Line data={chartDataEuro} options={chartOptions} />
              </div>

              <div
                id="paiementCFA"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2 border-orange-500 "
              >
                <Line data={chartDataCfa} options={chartOptions} />
              </div>
            </div>
            <div className="" />
            {/* <div className="mt-8 px-5 py-0 lg:m-1 rounded-lg shadow-md border-2 border-blue-500">
              <Line data={data} options={chartOptions} height={chartHeight} />
            </div> */}
          </>
        )}
      </div>
      <div className="p-4">
        <h3
          className="text-lg font-medium mb-2 cursor-pointer"
          onClick={toggleCollapse}
        >
          <FontAwesomeIcon
            icon={isOpen ? faArrowDown : faArrowRight}
            className="text-orange-500 hover:text-orange-600"
          />{" "}
          Voir Détail
        </h3>
        <p className="font-light text-orange-500 italic text-sm ml-5">
          {" "}
          si le mois n'apparaît pas cela veut dire que le montant est a 0
        </p>
        <div className={`collapse ${isOpen ? "" : "hidden"}`}>
          <div className="grid lg:grid-cols-3 divide-y-2 lg:divide-y-0">
            <div className="w-full mt-5 lg:mt-0 shadow-lg p-2 rounded-lg">
              <div className="text-center text-xl font-extralight ">
                Revenus en Euros{" "}
              </div>

              {dataEuro
                .slice(-5)
                .reverse()
                .map((item) => (
                  <div className="grid grid-cols-2">
                    <div className="col-span-1 uppercase text-right mr-3 font-thin   ">
                      {" "}
                      {item.date}
                    </div>
                    <div className="col-span-1 font-mono text-sm ">
                      {" "}
                      {separateThousandsMonetaryEuros(item.total)} €{" "}
                    </div>
                  </div>
                ))}
            </div>
            <div className="w-full mt-5 lg:mt-0 shadow-lg p-2 rounded-lg ">
              <div className="text-center text-xl font-extralight ">
                Revenus en GNF{" "}
              </div>
              {dataGnf
                .slice(-5)
                .reverse()
                .map((item) => (
                  <div className="grid grid-cols-2">
                    <div className="col-span-1 uppercase text-right mr-3 font-thin   ">
                      {" "}
                      {item.date}
                    </div>
                    <div className="col-span-1 font-mono text-sm ">
                      {" "}
                      {separateThousandsMonetary(item.total)} GNF{" "}
                    </div>
                  </div>
                ))}
            </div>
            <div className="w-full mt-5 lg:mt-0 shadow-lg p-2 rounded-lg">
              <div className="text-center text-xl font-extralight ">
                {" "}
                Revenus en CFA{" "}
              </div>
              {dataCfa
                .slice(-5)
                .reverse()
                .map((item) => (
                  <div className="grid grid-cols-2">
                    <div className="col-span-1 uppercase text-right mr-3 font-thin   ">
                      {" "}
                      {item.date}
                    </div>
                    <div className="col-span-1 font-mono text-sm ">
                      {" "}
                      {separateThousandsMonetary(item.total)} CFA{" "}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </TemplateHeaderContainter>
  );
}
