import axios from "axios";
import "../axios";

export const addDevise = async (form) => {
  let data = await axios.post("/devises", form);
  return data;
};

export const getAllDevises = async () => {
  let data = await axios.get("/devises");
  return data;
};

export const updateDevise = async (id, form) => {
  let data = await axios.patch(`/devises/${id}`, form);
  return data;
};

export const getDeviseInfo = async (id) => {
  let data = await axios.get(`/devises/${id}`);
  return data;
};
export const deleteDevise = async (id) => {
  let data = await axios.delete(`/devises/${id}`);
  return data;
};

export const sendContactMail = async (form) => {
  let data = await axios.post("devises/send-mail", form);
  return data;
};

export const forgotPassword = async (form) => {
  let data = await axios.post("devises/forgotpassword", form);
  return data;
};

export const resetPassword = async (token, form) => {
  let data = await axios.post(`devises/resetpassword/${token}`, form);
  return data;
};
