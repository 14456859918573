import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { useLogoutMutation } from "../../../redux/action/authAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  resetUserAdmin,
  selectCurrentUser,
} from "../../../redux/store/authStore";

import { SelectImage } from "../../../utils/process";
import { LOGO_WHITE } from "../../../shared/pictos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
export default function Header({ setSidebarOpen }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);

  const [logout, { isLoading, isSuccess, isError, error }] =
    useLogoutMutation();

  const onLogout = async () => {
    dispatch(resetUserAdmin(null));
  };

  return (
    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-gray-900 shadow">
      <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only"> Sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex ">
          <div className="grid grid-cols-8  lg:hidden ">
            <div className="col-span-2 mt-5 mx-auto">
              <FontAwesomeIcon
                onClick={() => history.goBack()}
                icon={faBackward}
                className="text-white flex justify-center"
              />
            </div>
            <div className="col-span-4 mx-auto">
              <img
                className="p-2 md:h-16 "
                src={LOGO_WHITE}
                alt=""
                onClick={() => history.push("/admin")}
              />
            </div>
            <div className="col-span-2 mt-5 mx-auto">
              <FontAwesomeIcon
                onClick={() => history.goForward()}
                icon={faForward}
                className="text-white flex justify-center"
              />
            </div>
          </div>
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative">
            <div>
              <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Menu</span>
                <img
                  className="h-8 w-8 rounded-full"
                  src={SelectImage(currentUser?.roles)}
                  alt={currentUser?.firstName}
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  <Link
                    to={`/admin/detail-user`}
                    className="text-left min-w-full bg-gray-100 block px-4 py-2 text-sm text-gray-700  hover:text-blue-500"
                  >
                    Mon Profil
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={onLogout}
                    className="text-left min-w-full bg-gray-100 block px-4 py-2 text-sm text-gray-700 hover:text-blue-500"
                  >
                    Déconnexion
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
