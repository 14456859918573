import React from "react";
import { Link } from "react-router-dom";
import TemplateHeaderContainter from "./TemplateHeaderContainter";
import notFoundImage from "../assets/svg/404-lagui.png";

const NotFound = () => {
  return (
    <TemplateHeaderContainter>
      <div className=" grid grid-rows-1 justify-center items-center mx-auto gap-5">
        <div className="lg:row-span-1 text-center">
          <h1 className="font-bold italic text-lg text-red-500">
            Erreur lors de la Requête{" "}
          </h1>
        </div>
        <div className="lg:col-span-4 mb-10 flex justify-center">
          <Link
            className="lg:w-full inline-flex  shadow-gray-500/50 justify-center mx-auto rounded-md  shadow-lg px-4 py-2 bg-teal-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:col-start-2 sm:text-sm"
            to={"/admin"}
          >
            Retour
          </Link>
        </div>
      </div>
      <img src={notFoundImage} alt={notFoundImage} className="w-3/6 mx-auto" />
    </TemplateHeaderContainter>
  );
};

export default NotFound;
