import React from "react";

import HashLoader from "react-spinners/HashLoader";

export default function Loader({ size, color }) {
  return (
    <div className="relative sm:py-2 mt-5 ">
      <div className=" mx-auto max-w-full max-h-full px-2 sm:max-w-xl sm:px-3 lg:px-8 lg:max-w-4xl">
        <div
          className="flex flex-wrap justify-center "
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            justifyContent: "center",
          }}
        >
          <HashLoader
            color={color ? color : "#36d7b7"}
            loading={true}
            speedMultiplier={1}
            size={size ? size : 25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    </div>
  );
}
