import { createSlice } from "@reduxjs/toolkit";
import { authAction } from "../action/authAction";

const initialState = {
  category: null,
  devises: null,
  sizeSideBarFromStore: 40,
};
const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setCategoryRedux: (state, action) => {
      const { payload } = action;
      state.category = payload;
      return state;
    },
    setSizeRedux: (state, action) => {
      const { payload } = action;
      state.sizeSideBarFromStore = payload;
      return state;
    },
  },
});

// Reducers
export default appSlice.reducer;

// Selectors
export const categorySelector = (state) => state.app.category;
export const sizeSidebarSelector = (state) => state.app.setCategoryRedux;

// Actions
export const { setCategoryRedux, setSizeRedux } = appSlice.actions;
