import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import TablePaiements from "../../components/TableComponent/TablePaiements";
import { getAllPaiements, getPaiementByAdmin } from "../../api/paiementsApi";
import { selectCurrentUser } from "../../redux/store/authStore";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PaiementPage() {
  const user = useSelector(selectCurrentUser);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [devises, setDevises] = useState();
  const [paiements, setPaiements] = useState([]);
  const [tempPaiement, setTempPaiement] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const allPaiements = async () => {
    setLoading(true);

    await getPaiementByAdmin(user.id)
      .then((res) => {
        setTempPaiement(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setEnabled(true);
    allPaiements();
  }, [user]);

  // if enabled is true send only data with paiement is ok
  useEffect(() => {
    if (enabled) {
      setPaiements(tempPaiement.filter((item) => item.status === true));
    } else {
      setPaiements(tempPaiement);
    }
  }, [user, enabled, loading]);

  return (
    <TemplateHeaderContainter title="Paiements" dataCount={paiements?.length}>
      <div className="px-5">
        <div className="p-1">
          <div className=" grid lg:grid-cols-3 sm:grid-cols-1  mx-auto ">
            {/* Replace with your content */}
          </div>

          {user?.roles === "admin" && (
            <div className="flex">
              <div className="mr-5">
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                  />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "bg-sky-600" : "bg-gray-200",
                      "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                    )}
                  />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
              <label className="w-36"> {enabled ? "Actif" : "Tout"}</label>
            </div>
          )}

          <TablePaiements
            setDevises={setDevises}
            open={open}
            setOpen={setOpen}
            paiements={paiements}
            setPaiements={setPaiements}
            loading={loading}
            allPaiements={allPaiements}
          />
        </div>
      </div>
    </TemplateHeaderContainter>
  );
}

// const data = [
//   {
//     id: 407,
//     reference: "ref",
//     operator: "orange money",
//     amount_paid: 2500,
//     status: true,
//     devise: "GNF",
//     day_active: 3,
//     start_date: "2023-09-02T15:19:22.699Z",
//     adminId: null,
//     financial_transaction: "MP230902.1519.C28225",
//     createdAt: "2023-09-02T15:17:59.555Z",
//     updatedAt: "2023-09-02T15:19:22.699Z",
//     publicationId: 4,
//     clientId: null,
//     publication: {
//       id: 4,
//       title: "Safiatou",
//       language: "Pular",
//       description:
//         "C'est l'histoire de 3 copines qui quitte le village pour la capitale et se retrouvent face a une realite qui etait tout autre.",
//       quality: "Full HD",
//       image_banner:
//         "https://lagui-cinema-mobile.s3.ca-central-1.amazonaws.com/cover/1690921866176Pia%20Study%201.jpg",
//       trailer_link: "https://www.youtube.com/embed/PB14Jc5xmts",
//       release_date: "2023-04-05T00:00:00.000Z",
//       availability: true,
//       formule: "payant",
//       createdAt: "2023-04-21T05:17:44.116Z",
//       updatedAt: "2023-09-02T11:38:09.744Z",
//       categoryId: 1,
//       planTarifaireId: 3,
//       adminId: 6,
//     },
//     client: null,
//   },
//   {
//     id: 406,
//     reference: "ref",
//     operator: "orange money",
//     amount_paid: 20000,
//     status: true,
//     devise: "GNF",
//     day_active: 4,
//     start_date: "2023-09-02T15:06:30.658Z",
//     adminId: null,
//     financial_transaction: "MP230902.1506.B28106",
//     createdAt: "2023-09-02T15:05:35.597Z",
//     updatedAt: "2023-09-02T15:06:30.658Z",
//     publicationId: 6,
//     clientId: 558,
//     publication: {
//       id: 6,
//       title: "Bidho Alphadjo on",
//       language: "Pular",
//       description:
//         "C'est l'histoire d'un roi désireux que son fils devienne une personne honorable. Malgré de nombreuses tentatives infructueuses, le prince semblait indifférent. Cependant, un jeune esclave émergea lors d'un affrontement avec le prince, remportant la victoire. Le roi envisagea de le faire tuer, mais une révélation divine lui apprit que cet esclave était en réalité son fils. Sa conception était le résultat du lait qu'il avait offert à sa femme, mais qui avait été consommé par sa servante à la place de la reine.",
//       quality: "HD",
//       image_banner:
//         "https://lagui-cinema-mobile.s3.ca-central-1.amazonaws.com/cover/1692994770680Brown%20Mystery%20Movie%20Poster%20%20%28600%C2%A0%C3%97%C2%A0850%C2%A0px%29.jpg",
//       trailer_link:
//         "https://www.youtube.com/embed/XDlV9_JoXVU?si=I0v_XhbM71dO1XJO",
//       release_date: "2023-09-01T00:00:00.000Z",
//       availability: true,
//       formule: "payant",
//       createdAt: "2023-08-25T20:19:31.884Z",
//       updatedAt: "2023-08-27T20:17:06.885Z",
//       categoryId: 1,
//       planTarifaireId: 11,
//       adminId: 16,
//     },
//     client: {
//       id: 558,
//       email: "konfatima2@gmail.com",
//       identifiant: "xk7ke",
//       firstName: "Fatima",
//       image:
//         "https://github.com/ibou18/logo/blob/main/Picto%20profils/profil2.png?raw=true/",
//       lastName: "Kone",
//       phone: "627211285",
//       indicatif: "+224",
//       status: true,
//       tokens: null,
//       password: "$2b$10$w3qtrl/8MPZIrWN1/GlTMuRpkj4KfiRelF.8wgO/VDtOGnCAR1zT6",
//       provider: "credential",
//       country_user: null,
//       ref_3: null,
//       ref_4: null,
//       ref_5: null,
//       createdAt: "2023-09-02T11:18:13.838Z",
//       updatedAt: "2023-09-02T11:18:13.838Z",
//       countryId: null,
//     },
//   },
// ];
