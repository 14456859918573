import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { addPaiement, addPaiementAdmin } from "../../api/paiementsApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/store/authStore";

const ModalConfirmation = ({
  ResetPassword,
  open,
  setOpen,
  data,
  setNewPassword,
  newPassword,
}) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-2 pb-40 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full align-bottom mx-3 bg-white rounded-lg px-3 pt-3 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="-mt-2 text-lg italic h-10 bg-slate-800 text-emerald-300 w-3/4 mx-auto justify-center pt-1  text-center rounded-lg">
                  Client N° {data.id} -{" "}
                  <span className="capitalize "> {data.firstName}</span>
                </div>
                <div className="mt-2 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-extralight  text-center leading-10 text-gray-900"
                  ></Dialog.Title>
                </div>
                <div className="text-center m-2">
                  Mot de passe par default :
                  <span className="font-semibold text-lg "> {newPassword}</span>
                </div>
                <div className="mx-auto">
                  Mot de passe :
                  <input
                    className="mt-2 ml-5 focus:ring-indigo-500 h-8 w-1/2 mx-auto text-red-600 border-gray-300 rounded"
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    id="password"
                    name="password"
                    value={newPassword}
                    type="text"
                  />
                </div>

                {/*  Submit */}
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                    onClick={ResetPassword}
                  >
                    Changer le mot de passe
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
};

export default ModalConfirmation;
