import React, {
  useState,
  useEffect,
  Fragment,
  useMemo,
  useCallback,
} from "react";

import Loader from "../../components/shared/Loader";
import { useSelector } from "react-redux";
import Pagination from "../../components/shared/Pagination";
import { debounce } from "lodash";
import { FaDollarSign, FaEye, FaLock, FaTrash } from "react-icons/fa";
import {
  addActor,
  deleteActor,
  getAllActors,
  updateActor,
} from "../../api/actorsApi";
import { Link, useHistory } from "react-router-dom";
import GenericModalDelete from "../../components/shared/GenericModalDelete";

import ModalUpdateClient from "../../components/Modals/ModalUpdateClient";
import ModalActivate from "../../components/Modals/ModalActivate";
import ModalBlockClient from "../../components/Modals/ModalBlockClient";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import ModalMessageInfo from "../../components/Modals/ModalMessageInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { getAllPublications } from "../../api/publicationApi";

let PageSize = 15;

export default function ActeurPage() {
  let history = useHistory();
  const roles = useSelector((state) => state.auth?.user?.roles);

  // Manage Modals States
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openActivate, setOpenActivate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMessageInfo, setOpenMessageInfo] = useState(false);
  const [openBlockClient, setOpenBlockClient] = useState(false);

  const [actors, setActors] = useState();
  const [movies, setMovies] = useState([]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [query, setQuery] = useState("");
  const [checked, setChecked] = useState(true);
  const [passwordTemp, setPasswordTemp] = useState("");
  const [infoUser, setInfoUser] = useState({
    id: "",
    name: "",
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [form, setForm] = useState({
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    password: "",
    status: true,
  });

  const [formActive, setFormActive] = useState({
    nombre_de_jours: null,
    montant_paye: null,
    operateur: null,
    devise: "",
  });

  const [formUpdate, setFormUpdate] = useState({
    id: form.id,
    firstname: form.firstname,
    lastname: form.lastname,
    email: form.email,
    telephone: form.telephone,
    password: form.password,
    status: form.status,
  });

  const [formClient, setFormClient] = useState({
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    password: "",
    status: "",
  });

  function createClient(item, value) {
    setFormClient({
      ...formClient,
      [item]: value,
    });
  }

  const getDataActors = async () => {
    const data = await getAllActors();
    setActors(data.data.data);
    console.log(data.data.data);
    setLoading(false);
  };

  const getDataMovies = async () => {
    const data = await getAllPublications();
    setMovies(data.data.data);
    console.log(data.data.data);
    setLoading(false);
  };

  const onCreate = async () => {
    formClient.status = checked;

    await addClient(formClient)
      .then((res) => {
        setMessage("Client ajouter avec succès");
        getDataActors();
        setOpen(false);
        setLoading(false);
      })
      .then((err) => console.log("err", err));
  };

  const onblock = async () => {
    let _tempForm = null;
    if (form.status === true) {
      _tempForm = { ...form, status: false };
    } else {
      _tempForm = { ...form, status: true };
    }
    await updateClient(form.id, _tempForm).then((res) => {
      setMessage("Client Bloquer avec succès");
      getDataActors();
      setOpenBlockClient(false);
    });
  };

  useEffect(() => {
    getDataActors();
    getDataMovies();
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return actors?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, actors]);

  function handleSearch(event) {
    setQuery(event.target.value);
  }

  const onDelete = async () => {
    setLoading(true);
    await deleteClient(infoUser.id)
      .then(() => {
        setOpenDelete(false);
        getDataActors();
        setTimeout(() => {
          setLoading(false);
          history.push("/admin/actors");
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  function handleUpdateClient(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  function handleActive(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  // Update Client
  const onUpdateClient = async () => {
    setLoading(true);
    await updateClient(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  // Activate Client for movie
  const onActivate = async () => {
    console.log("Activate ;-)");
    setLoading(true);
    await updateClient(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  // Retarde L'appel lors de la saisie de la recherche !
  const debouncedSearch = useCallback(debounce(handleSearch, 800), []);

  return (
    <TemplateHeaderContainter title={`Acteurs`} dataCount={actors?.length}>
      <div className="">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* <h1 className="mb-2 text-2xl font-semibold text-gray-900">
          Liste des Clients{" "}
          <span className="italic text-md font-light text-gray-500">
            ( {actors?.length} )
          </span>
        </h1> */}

          <button
            onClick={() => setOpen(true)}
            type="button"
            className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Ajouter un Acteur
          </button>
        </div>

        <div className="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div>
            <label className="ml-3 text-gray-500" htmlFor="">
              Recherche :
            </label>
          </div>

          <input
            className="border-5 pl-3 w-full lg:w-3/5 h-12 rounded-lg shadow-md mb-5 border-b-2 border-red-500"
            type="text"
            placeholder="Saisir une Information pour la recherche"
            onChange={debouncedSearch}
          />

          {(!currentTableData && actors === undefined) || loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            #ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Nom - Prénom
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Pays - Ville
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Téléphone
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Email
                          </th>
                        </tr>
                      </thead>
                      {query !== "" ? (
                        <tbody className="bg-white divide-y divide-gray-200">
                          {actors
                            ?.filter((val) => {
                              if (val === "") {
                                return val;
                              } else if (
                                val?.telephone
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase()) ||
                                val?.firstname
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase()) ||
                                val?.lastname
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase()) ||
                                val?.email
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase()) ||
                                val?.country
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase())
                              ) {
                                return val;
                              }
                              return null;
                            })
                            .map((user, index) => (
                              <tr key={index}>
                                <td className="px-5 py-1 whitespace-nowrap relative flex justify-center align-middle mt-5">
                                  <span className="inline-flex items-center p-1.5 ml-2 rounded-full text-xs font-medium bg-blue-500 ">
                                    <Link
                                      to={`/admin/detail-dealer/${user.id}`}
                                      className
                                    >
                                      <FaEye className="text-white h-4 w-4" />
                                    </Link>
                                  </span>
                                </td>

                                <td className="px-5 py-1 whitespace-nowrap relative">
                                  <div className="flex items-center justify-center ">
                                    <div
                                      className="ml-8 w-full"
                                      onClick={() => {
                                        setFormUpdate({
                                          id: user.id,
                                          firstname: user.firstname,
                                          lastname: user.lastname,
                                          mail: user.mail,
                                          telephone: user.telephone,
                                          country: user.country,
                                          city: user.city,
                                          image: user.image,
                                          status: user.status,
                                        });
                                        setOpenUpdate(true);
                                      }}
                                    >
                                      <div className="text-sm font-medium  text-gray-900 cursor-pointer">
                                        {user.firstname} {user.lastname}
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        {user.email}
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        {user.telephone}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="grid grid-rows-2 absolute left-2 top-2 mt-2">
                                    <div className="text-sm text-gray-900 text-center ml-2 mt-4">
                                      {user.status === true ? (
                                        <div className="bg-green-500 text-white rounded-full h-3 w-3" />
                                      ) : (
                                        <div className="bg-red-500 text-white rounded-full h-3 w-3" />
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-5 py-1 whitespace-nowrap relative">
                                  <div className="flex items-center justify-left ">
                                    {user.country}
                                  </div>
                                  <div className="flex items-center justify-left ">
                                    {user.city}
                                  </div>
                                </td>
                                <td className="px-5 py-1 whitespace-nowrap relative">
                                  {user.telephone}
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap ">
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-orange-500 mr-3">
                                    <button
                                      onClick={() => {
                                        setForm({
                                          id: user.id,
                                          firstname: user.firstname,
                                          lastname: user.lastname,
                                          email: user.email,
                                          address: user.address,
                                          telephone: user.telephone,
                                          city: user.city,
                                          postalCode: user.postalCode,
                                          status: user.status,
                                        });
                                        setOpenBlockClient(true);

                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaLock className="text-white h-4 w-4" />
                                    </button>
                                  </span>
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-blue-500 ">
                                    <Link
                                      to={`/admin/detail-client/${user.id}`}
                                      className
                                    >
                                      <FaEye className="text-white h-4 w-4" />
                                    </Link>
                                  </span>
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-red-500 ml-2">
                                    <button
                                      onClick={() => {
                                        setInfoUser({
                                          id: user.id,
                                          name: user.firstname,
                                        });
                                        setOpenDelete(true);

                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaTrash className="text-white h-4 w-4" />
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <tbody className="bg-white divide-y divide-gray-200">
                          {currentTableData !== undefined &&
                            currentTableData?.map((user, index) => (
                              <tr key={index}>
                                <td className="px-5 py-1 whitespace-nowrap relative flex justify-center align-middle mt-3  ">
                                  <span className="inline-flex items-center p-1.5 ml-2 rounded-full text-xs font-medium bg-blue-500 ">
                                    <Link
                                      to={`/admin/detail-acteur/${user.id}`}
                                      className
                                    >
                                      <FaEye className="text-white h-4 w-4" />
                                    </Link>
                                  </span>
                                </td>
                                <td className="px-5 py-1 whitespace-nowrap relative">
                                  <div className="flex items-center justify-center">
                                    <div
                                      className="ml-8 w-full"
                                      onClick={() => {
                                        setFormUpdate({
                                          id: user.id,
                                          firstname: user.firstname,
                                          lastname: user.lastname,
                                          email: user.email,
                                          telephone: user.telephone,
                                          status: user.status,
                                        });
                                        setOpenUpdate(true);
                                      }}
                                    >
                                      <div className="text-sm font-medium text-gray-900 cursor-pointer">
                                        {user.firstname} {user.lastname}
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        {user.email}
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        {user.telephone}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="grid grid-rows-2 absolute left-2 top-2 mt-2">
                                    <div className="text-sm text-gray-900 text-center ml-2 mt-4">
                                      {user.status === true ? (
                                        <div className="bg-green-500 text-white rounded-full h-3 w-3" />
                                      ) : (
                                        <div className="bg-red-500 text-white rounded-full h-3 w-3" />
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-5 py-1 whitespace-nowrap relative">
                                  <div className="flex items-center justify-left ">
                                    {user.country}
                                  </div>
                                  <div className="flex items-center justify-left ">
                                    {user.city}
                                  </div>
                                </td>
                                <td className="px-5 py-1 whitespace-nowrap relative">
                                  {user.telephone}
                                </td>
                                {/* button */}
                                <td className="px-6 py-4 whitespace-nowrap ">
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-orange-500 mr-3">
                                    <button
                                      onClick={() => {
                                        setForm({
                                          id: user.id,
                                          firstname: user.firstname,
                                          lastname: user.lastname,
                                          email: user.email,
                                          address: user.address,
                                          telephone: user.telephone,
                                          city: user.city,
                                          postalCode: user.postalCode,
                                          status: user.status,
                                        });
                                        setOpenBlockClient(true);

                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaLock className="text-white h-4 w-4" />
                                    </button>
                                  </span>
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-blue-500 ">
                                    <Link
                                      to={`/admin/detail-acteur/${user.id}`}
                                      className
                                    >
                                      <FaEye className="text-white h-4 w-4" />
                                    </Link>
                                  </span>

                                  <span
                                    className={`inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-red-500 ml-2`}
                                  >
                                    <button
                                      disabled={roles !== "admin"}
                                      onClick={() => {
                                        setInfoUser({
                                          id: user.id,
                                          name: user.firstname,
                                        });
                                        setOpenDelete(true);
                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaTrash
                                        className={`text-white h-4 w-4 bg-red-500 ${
                                          roles !== "admin" &&
                                          "cursor-not-allowed"
                                        } `}
                                      />
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {currentTableData && query === "" ? (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={actors?.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        ) : null}

        {/* Modal delete Client */}
        <GenericModalDelete
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          message={"Etes-vous sûre de vouloir supprimer ?"}
          data={infoUser.name}
          onDelete={onDelete}
        />

        {/* Modal add Artiste */}

        {/* Modal Update Client */}
        <ModalUpdateClient
          formUpdate={formUpdate}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          handleUpdateClient={handleUpdateClient}
          onUpdateClient={onUpdateClient}
        />

        {/* Modal Activate Client */}
        <ModalActivate
          formActive={formActive}
          open={openActivate}
          setOpen={setOpenActivate}
          handleActive={handleActive}
          onActivate={onActivate}
          data={form}
          movies={movies}
        />

        {/* Modal Update Client */}
        <ModalMessageInfo
          open={openMessageInfo}
          setOpen={setOpenMessageInfo}
          data={form}
          picto={<FontAwesomeIcon icon={faBan} />}
        />

        {/* Modal Block Client */}
        <ModalBlockClient
          formActive={formActive}
          open={openBlockClient}
          setOpen={setOpenBlockClient}
          onblock={onblock}
          data={form}
        />
      </div>
    </TemplateHeaderContainter>
  );
}
