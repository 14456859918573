import adminPicto from "../assets/profile/admin.png";
import producteur from "../assets/profile/producteur.png";
import superviseur from "../assets/profile/superviseur.png";
import logoWhite from "../assets/logo_white.png";
import partenaire from "../assets/profile/partenaire.png";

export const LOGO_WHITE = logoWhite;
export const ADMIN_PICTO = adminPicto;
export const PRODUCTEUR_PICTO = producteur;
export const SUPERVISEUR_PICTO = superviseur;
export const PARTENAIRE_PICTO = partenaire;
