import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

import { getAllPublications } from "../../api/publicationApi";
import GenericModalDelete from "../../components/shared/GenericModalDelete";
// import { ModalUpdatedealer } from "../../components/Modals/ModalUpdateUser";
import Loader from "../../components/shared/Loader";

import moment from "moment";
moment.locale("fr");

import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import ModalActivate from "../../components/Modals/ModalActivate";
import PaiementTable from "../../components/shared/PaiementTable";
import { ToastContainer, toast } from "react-toastify";
import { getDealerInfo, getPaiementBydealer } from "../../api/dealersApi";
import { getPaiementByAdmin } from "../../api/paiementsApi";

const DetailDealer = () => {
  let history = useHistory();
  let { id } = useParams();
  const [openUpdate, setOpenUpdate] = useState(false);

  const [dealer, setDealer] = useState({
    id: "",
    mail: "",
    firstName: "",
    lastName: "",
    phone: "",
    status: "",
    createdAt: "",
  });

  const [openDelete, setOpenDelete] = useState(false);
  const [openModalActive, setOpenModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paiements, setPaiements] = useState([]);
  const [movies, setMovies] = useState([]);

  const [formUpdate, setFormUpdate] = useState({
    id: dealer?.id,
    mail: dealer?.mail,
    firstName: dealer?.firstName,
    lastName: dealer?.lastName,
    phone: dealer?.phone,
    status: dealer?.status,
  });

  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    mail: "",
    phone: "",
    password: "",
    status: true,
  });

  const [formActive, setFormActive] = useState({
    nombre_de_jours: null,
    montant_paye: null,
    operateur: null,
    devise: "",
  });

  function handleUpdatedealer(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  const getRevendeur = async () => {
    try {
      await getDealerInfo(id)
        .then((res) => {
          console.log("res", res.data.data);
          setDealer(res.data.data);
          getPaiementByAdmin(id).then((res) => {
            console.log("res.data Paiement :>> ", res.data);
          });
        })
        .catch((err) => {
          console.log(err);
          history.push("/admin/not-found");
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getRevendeur();
    getDataMovies();
  }, []);

  const onDelete = async () => {
    setLoading(true);
    await deleteDealer(dealer?.id)
      .then(() => {
        setOpenDelete(false);
        setTimeout(() => {
          setLoading(false);
          history.push("/admin/dealers");
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  const onUpdatedealer = async (id) => {
    setLoading(true);
    await updateDealer(dealer?.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getdealer();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  const getDataMovies = async () => {
    const data = await getAllPublications();
    setMovies(data.data.data);
    console.log(data.data.data);
    setLoading(false);
  };

  // Activate dealer for movie
  const onActivate = async () => {
    setLoading(true);
    await updateDealer(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        toast.success("🚀 Activation avec succès !!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };
  console.log("dealer :>> ", dealer);

  return (
    <TemplateHeaderContainter
      title={`Compte de ${dealer?.firstName}`}
      className=""
    >
      <div className=" px-4 sm:px-6 md:px-8">
        <Link
          to={"/admin/dealers"}
          type="button"
          className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Retour Liste des dealers
        </Link>
      </div>
      {loading || !dealer || dealer === undefined ? (
        <Loader />
      ) : (
        <div className=" flex justify-center mx-2 lg:mx-10">
          <div className="w-full p-1">
            <div className="bg-white relative shadow-lg hover:shadow-xl transition duration-500 rounded-lg">
              <img className="rounded-t-lg" src="" alt="" />
              <div className="py-3 px-2 rounded-lg bg-white">
                <div className="flex justify-center mb-4 ">
                  <h1 className="text-gray-700 uppercase font-bold text-lg md:text-2xl hover:text-gray-900 ">
                    {dealer?.firstName + " " + dealer?.lastName}
                  </h1>
                  <span className="tracking-wide ml-3">
                    {dealer?.status === true ? (
                      <p className=" px-3 rounded-xl uppercase bg-green-500 text-white font-normal text-sm w-24 h-7 flex justify-center pt-1">
                        Actif
                      </p>
                    ) : (
                      <p className=" px-3 rounded-xl uppercase bg-red-500 text-white font-normal text-sm w-24 h-7 flex justify-center pt-1">
                        bloqué
                      </p>
                    )}
                  </span>
                </div>

                <div className="grid lg:grid-cols-2 grid-cols-1 ml-5 gap-y-0 lg:gap-3 ">
                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-2/6 ">
                      Date Création :{" "}
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {moment(dealer?.createdAt).format("DD MMM YYYY hh:mm")}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-2/6 ">
                      Téléphone :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {dealer?.phone}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-2/6 ">
                      E-mail :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {dealer?.mail}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-2/6 ">
                      Mis à jour :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {moment(dealer?.updatedAt).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 mt-3">
                  <button
                    onClick={() => {
                      setOpenModalActive(true);
                    }}
                    className="ml-2 py-1.5 px-2 bg-green-400 text-gray-800 font-bold rounded-lg shadow-md hover:shadow-lg transition duration-300 hover:bg-green-500"
                  >
                    Activé
                  </button>
                  <button
                    onClick={() => {
                      setOpenUpdate(true);
                      setFormUpdate({
                        id: dealer?.id,
                        mail: dealer?.mail,
                        firstName: dealer?.firstName,
                        lastName: dealer?.lastName,
                        phone: dealer?.phone,
                        status: dealer?.status,
                      });

                      // setIsEditable(!isEditable);
                      // setStatusEdit(false); // False = Add step
                    }}
                    className="ml-2 py-1.5 px-2 bg-yellow-400 text-gray-800 font-bold rounded-lg shadow-md hover:shadow-lg transition duration-300 hover:bg-yellow-500"
                  >
                    Modifier
                  </button>
                  <button
                    onClick={() => setOpenDelete(true)}
                    className="ml-2 py-1.5 px-2 bg-red-400 text-white font-bold rounded-lg shadow-md hover:shadow-lg transition duration-300 hover:bg-red-500"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    <span className="ml-2"> Supprimer</span>
                  </button>
                </div>
              </div>
              <div className="absolute -top-32 -right-5  md:top-4 md:right-4 py-2 px-2 bg-white rounded-lg">
                <span className="text-lg md:text-2xl"> </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <h1 className="text-gray-700 font-bold ml-3 mt-12 text-lg md:text-2xl mb-2 hover:text-gray-900 ">
        Historique des paiements
      </h1>
      {loading ? (
        <Loader />
      ) : (
        <div className="mt-2 ml-2">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <PaiementTable paiements={paiements} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <GenericModalDelete
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        message={"Etes-vous sûre de vouloir supprimer ?"}
        data={dealer?.firstName + " " + dealer?.lastName}
        onDelete={onDelete}
      />

      {/* Modal Update dealer */}
      {/* {dealer && (
        <ModalUpdatedealer
          formUpdate={dealer}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          handleUpdatedealer={handleUpdatedealer}
          onUpdatedealer={onUpdatedealer}
        />
      )} */}

      {/* Modal Update dealer */}
      <ModalActivate
        formActive={formActive}
        open={openModalActive}
        setOpen={setOpenModalActive}
        onActivate={onActivate}
        data={form}
        movies={movies}
      />
      <ToastContainer />
    </TemplateHeaderContainter>
  );
};

export default DetailDealer;
