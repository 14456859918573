import React, { useEffect, useState } from "react";

import TitleComponent from "../../shared/TitleComponent";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

import GenericButton from "../../components/shared/GenericButton";
import TableDevise from "../../components/TableComponent/TableDevise";
import { getAllDevises } from "../../api/devisesApi";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";

export default function DevisePage() {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [devises, setDevises] = useState();

  // const allCategories = async () => {
  //   await getAllDevises()
  //     .then((res) => setDevises(res.data.data))
  //     .catch((err) => console.log("Error", err));
  // };

  // useEffect(() => {
  //   allCategories();
  // }, []);

  const addCategory = () => {};

  return (
    <TemplateHeaderContainter
      title="Liste des Devises"
      dataCount={devises?.length}
    >
      {/* <TitleComponent
        title={`Devises`}
        icon={faDollarSign}
        color={"text-blue-700"}
      /> */}
      <div className="px-5">
        {/* <div className="text-md pl-5">
          <h3> Liste des Devises</h3>
        </div> */}
        {/* name, bgColor, textcolor, handleClick */}
        {/* <GenericButton
          textcolor={"white"}
          bgColor={"teal"}
          name="+ Devise"
          handleClick={() => setOpen(true)}
        /> */}

        <button
          onClick={() => setOpen(true)}
          className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Ajouter Devise
        </button>
        <div className="p-1">
          <div className=" grid lg:grid-cols-3 sm:grid-cols-1  mx-auto ">
            {/* Replace with your content */}
          </div>

          <TableDevise
            data={devises}
            setDevises={setDevises}
            open={open}
            setOpen={setOpen}
          />
        </div>
      </div>
    </TemplateHeaderContainter>
  );
}
