import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  setUserAdmin,
} from "../../../redux/store/authStore";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { updateUser } from "../../../api/usersApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateUserPage = () => {
  const currentUser = useSelector(selectCurrentUser);
  const [edit, setEdit] = useState(false);
  const [form, setForm] = useState(currentUser);

  const dispatch = useDispatch();

  const onSave = () => {
    updateUser(currentUser.id, form).then((res) => {
      setEdit(false);
      dispatch(setUserAdmin({ user: form }));
      toast.success("🚀 Votre compte a été Modifié !!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  };

  const updateField = async (item, value) => {
    setForm({
      ...form,
      [item]: value,
    });
  };

  return (
    <div>
      <div className=" text-justify font-light mx-4">
        <div className="grid lg:grid-cols-2">
          <div className="grid grid-cols-5 lg:grid-cols-3 mt-2">
            <div className="lg:col-span-2 col-span-2 text-justify mr-2 ">
              Prénom :
            </div>

            {!edit ? (
              <p className="font-bold  "> {currentUser?.firstName} </p>
            ) : (
              <input
                className="lg:col-span-1 col-span-3 rounded-lg py-1 px-2 h-7 border-gray-400 border-1 w-56"
                name="firstName"
                type="text"
                value={form.firstName}
                onChange={(e) => {
                  updateField("firstName", e.target.value);
                }}
              />
            )}
          </div>
        </div>
        <div className="grid lg:grid-cols-2 mt-2">
          <div className="grid grid-cols-5 lg:grid-cols-3">
            <div className="col-span-2 text-justify  mr-2"> Nom : </div>
            {!edit ? (
              <p className="font-bold  "> {currentUser?.lastName} </p>
            ) : (
              <input
                className="col-span-1 rounded-lg py-1 px-2 h-7 border-gray-400 border-1 w-56"
                name="lastName"
                type="text"
                value={form.lastName}
                onChange={(e) => {
                  updateField("lastName", e.target.value);
                }}
              />
            )}
          </div>
        </div>
        <div className="grid lg:grid-cols-2 mt-2">
          <div className="grid grid-cols-5 lg:grid-cols-3">
            <div className="col-span-2 text-justify  mr-2">E-mail :</div>
            {!edit ? (
              <p className="font-bold  "> {currentUser?.email} </p>
            ) : (
              <input
                className="col-span-1 rounded-lg py-1 px-2 h-7 border-gray-400 border-1 w-56"
                name="email"
                type="mail"
                value={form.email}
                onChange={(e) => {
                  updateField("email", e.target.value);
                }}
              />
            )}
          </div>
        </div>
        <div className="grid lg:grid-cols-2 mt-2">
          <div className="grid grid-cols-5 lg:grid-cols-3">
            <div className="col-span-2 text-justify  mr-2"> Téléphone : </div>
            {!edit ? (
              <p className="font-bold  "> {currentUser?.telephone} </p>
            ) : (
              <input
                className="col-span-1 rounded-lg py-1 px-2 h-7 border-gray-400 border-1 w-56"
                name="telephone"
                type="tel"
                value={form.telephone}
                onChange={(e) => {
                  updateField("telephone", e.target.value);
                }}
              />
            )}
          </div>
        </div>
        <div className="grid lg:grid-cols-2 mt-2">
          <div className="grid grid-cols-5 lg:grid-cols-3">
            <div className="col-span-2 text-justify mr-2"> Status : </div>
            <div className="col-span-1 ">
              {currentUser.isActive ? (
                <span className="bg-teal-500 px-2 py-0.5 font-semibold italic text-white text-xs flex justify-center uppercase rounded-full ">
                  Actif
                </span>
              ) : (
                <span className="bg-red-500 px-2 py-0.5 font-semibold italic text-white text-xs flex justify-center uppercase rounded-full ">
                  Inactif
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 mt-2">
          <div className="grid grid-cols-5 lg:grid-cols-3">
            <div className="col-span-2 text-justify mr-2"> Droits : </div>
            <div className="col-span-1 ">
              <span className="bg-orange-500 px-2 py-0.5 font-semibold italic text-white text-xs flex justify-center uppercase rounded-full ">
                {currentUser.roles}
              </span>
            </div>
          </div>
        </div>
      </div>

      {!edit ? (
        <button
          className="inline-flex ml-10 lg:w-1/5 justify-center  mx-auto mt-5 px-5 rounded-md  p-1.5 border border-red shadow-sm text-white bg-orange-600 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          onClick={() => {
            setEdit(true);
          }}
        >
          <FontAwesomeIcon className="mr-5 mt-1" icon={faEdit} />
          Edit
        </button>
      ) : (
        <div>
          <button
            className="inline-flex ml-10 lg:w-1/5 mx-auto mt-5 px-5 rounded-md   p-1.5 border border-red shadow-sm text-white bg-teal-600 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
            onClick={onSave}
          >
            <FontAwesomeIcon className="mr-5 mt-1  " icon={faSave} />
            Enregistrer
          </button>
          <button
            className="inline-flex ml-10 lg:w-1/5 mx-auto mt-5 px-5 rounded-md   p-1.5 border border-red shadow-sm text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            onClick={() => {
              setEdit(false);
            }}
          >
            <FontAwesomeIcon className="mr-5 mt-1" icon={faCancel} />
            Annuler
          </button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default UpdateUserPage;
