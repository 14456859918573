import React, { useMemo, useState, useEffect } from "react";

import TitleComponent from "../../shared/TitleComponent";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import GenericTableContenu from "../../components/TableComponent/GenericTableContenu";

import {
  deletePublication,
  getAllPublications,
  getPublicationByAdmin,
} from "../../api/publicationApi";
import Loader from "../../components/shared/Loader";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/store/authStore";

export default function Publications() {
  const user = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(true);
  const [publications, setPublications] = useState([]);
  const [idMovie, setIdMovie] = useState();

  const getData = async () => {
    setLoading(true);
    if (user.roles === "producteur") {
      const { data } = await getPublicationByAdmin(user.id);
      setPublications(data.data);
      setLoading(false);
    } else {
      const { data } = await getAllPublications();
      setPublications(data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [user]);

  const onDelete = async () => {
    setLoading(true);
    await deletePublication(idMovie.id);
    setLoading(false);
    getData();
  };

  return (
    <TemplateHeaderContainter
      title={`Contenus`}
      dataCount={publications?.length}
      className=""
    >
      <div className="px-5">
        {/* <GenericLink
          bgColor={"orange"}
          name={"Ajouter du contenu"}
          textcolor={"white"}
          redirectLink={"/admin/add-contenu"}
        /> */}

        <Link
          to={"/admin/add-contenu"}
          className="ml-5 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Ajouter du contenu
        </Link>

        <div className="p-1">
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className=" grid mx-auto ">
                {/* Replace with your content */}
              </div>
              <GenericTableContenu
                datas={publications}
                onDelete={onDelete}
                setIdMovie={setIdMovie}
                idMovie={idMovie}
              />
            </>
          )}
        </div>
      </div>
    </TemplateHeaderContainter>
  );
}
