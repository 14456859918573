import axios from "axios";
import "../axios";

export const addPaiement = async (form) => {
  let data = await axios.post("/paiements", form);
  return data;
};

export const addPaiementAdmin = async (form) => {
  let data = await axios.post("/paiements/admin", form);
  return data;
};

export const getAllPaiements = async () => {
  let data = await axios.get("/paiements");
  return data;
};
export const getStatsPaiement = async (id) => {
  let data = await axios.get(`/paiements/stats/${id}`);
  return data;
};

export const updatePaiement = async (id, form) => {
  let data = await axios.patch(`/paiements/${id}`, form);
  return data;
};
export const getPaiementByAdmin = async (id) => {
  let data = await axios.get(`/paiements/paiement-admins/${id}`);
  return data;
};

export const getPaiementInfo = async (id) => {
  let data = await axios.get(`/paiements/${id}`);
  return data;
};

export const deletePaiement = async (id) => {
  let data = await axios.delete(`/paiements/${id}`);
  return data;
};

export const getPaiementsToday = async () => {
  let data = await axios.get("/paiements/today");
  return data;
};
