import axios from "axios";
import "../axios";

export const addActor = async (form) => {
  let data = await axios.post("/actors/add", form);
  return data;
};

export const getAllActors = async () => {
  let data = await axios.get("/actors");
  return data;
};
export const getStatsActor = async (id) => {
  let data = await axios.get(`/actors/stats/${id}`);
  return data;
};

// export const updateActor = async (id, form) => {
//   let data = await axios.patch(`/actors/${id}`, form);
//   return data;
// };

export const getActorInfo = async (id) => {
  let data = await axios.get(`/actors/${id}`);
  return data;
};

export const deleteActor = async (id) => {
  let data = await axios.delete(`/actors/${id}`);
  return data;
};
