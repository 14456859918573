import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  Dashboard,
  UserPage,
  AddPublicationPage,
  Publications,
  DetailPublication,
  CategoriesPage,
  ClientsPage,
  DevisePage,
  DetailClient,
  SendLinkPage,
  UpdatePublicationPage,
  GenderPage,
  PaiementPage,
  NotFound,
  UserDetailPage,
} from "../../pages/admin";
import InfosPage from "../../pages/admin/InfosPage";
import DealerPage from "../../pages/admin/DealerPage";
import ActeurPage from "../../pages/admin/ActeurPage";
import DetailActor from "../../pages/admin/DetailActor";
import DetailDealer from "../../pages/admin/DetailDealer";
import DetailUser from "../../pages/admin/DetailUser";

let widthSideBar = 48;
export default function AdminRoute() {
  return (
    <>
      <Switch>
        <Route exact component={ActeurPage} path="/admin/acteur" />
        <Route exact component={DetailActor} path="/admin/detail-acteur/:id" />
        <Route exact component={DealerPage} path="/admin/revendeur" />
        <Route
          exact
          component={DetailDealer}
          path="/admin/detail-revendeur/:id"
        />
        <Route exact component={PaiementPage} path="/admin/paiement" />
        <Route exact component={UserPage} path="/admin/users" />
        <Route exact component={DetailUser} path="/admin/detail-user/:id" />
        <Route exact component={ClientsPage} path="/admin/clients" />
        <Route exact component={DetailClient} path="/admin/detail-client/:id" />
        <Route exact component={Publications} path="/admin/contenus" />
        <Route
          exact
          component={DetailPublication}
          path="/admin/detail-publication/:id"
        />
        <Route
          exact
          component={UpdatePublicationPage}
          path="/admin/update-publication/:id"
        />
        <Route exact component={AddPublicationPage} path="/admin/add-contenu" />
        <Route exact component={CategoriesPage} path="/admin/categories" />
        <Route exact component={SendLinkPage} path="/admin/send-link" />
        <Route exact component={InfosPage} path="/admin/infos" />
        <Route exact component={GenderPage} path="/admin/genders" />
        <Route exact component={DevisePage} path="/admin/devise" />
        <Route exact component={UserDetailPage} path="/admin/detail-user" />
        <Route exact component={NotFound} path="/admin/not-found" />
        <Route exact component={Dashboard} path="/" />
        <Route exact component={Dashboard} path="*" />
      </Switch>
    </>
  );
}
