import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import {
  addPaiementAdmin,
  deletePaiement,
  updatePaiement,
} from "../../api/paiementsApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/store/authStore";

const ModalDetailPaiement = ({ data, open, setOpen, allPaiements }) => {
  const [devise, setDevise] = useState();
  const [dayActive, setDayActive] = useState();
  const [dataToSend, setDataToSend] = useState();
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setDataToSend(data);
  }, [data]);

  function handleUpdate(item, value) {
    if (data.id)
      setDataToSend({
        ...dataToSend,
        [item]: value,
        financial_transaction: "admin-" + uuidv4(),
        partenaire_id: null,
        operator: "Agent" + "-" + currentUser.firstName,
        reference: "ref",
        start_date: Date.now(),
        clientId: data.id,
        status: true,
        adminId: currentUser.id,
      });
  }

  function handleUpdateBill(item, value) {
    if (data.id)
      setDataToSend({
        ...dataToSend,
        [item]: value,
      });
  }

  let dateNow = moment(Date.now());

  const sendData = async () => {
    dataToSend.devise = devise;
    dataToSend.day_active = dayActive;
    dataToSend.clientId = data.id;
    setLoading(true);

    await addPaiementAdmin(dataToSend)
      .then((res) => {
        setOpen(false);
        setDataToSend({ title: movies[0]?.title });
        setDayActive();
        toast.success("🚀 Paiement enregistré !!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const deleteBill = () => {
    deletePaiement(data.id)
      .then((res) => {
        setOpen(false);
        allPaiements();

        toast.success("Paiement supprimé avec Succès !", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch((err) => console.log("err", err));
  };

  const sendUpdate = () => {
    updatePaiement(data.id, dataToSend)
      .then((res) => {
        setIsEditable(false);
        console.log("res :>> ", res);
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-2 pb-40 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full align-bottom mx-3 bg-white rounded-lg px-3 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium text-center leading-10 bg-slate-900 text-emerald-300  rounded-lg mb-5 "
                    >
                      #{data.id} - Paiement de
                      <span className="">
                        {" "}
                        {data?.client?.firstName} {data?.client?.lastName}{" "}
                      </span>
                    </Dialog.Title>
                    <div className="grid gap-2">
                      <div className="grid grid-cols-1 sm:grid-cols-5">
                        <div className="col-span-1 text-base font-bold capitalize flex lg:justify-end ">
                          Créer le :{" "}
                        </div>
                        <div className="col-span-3 text-sm font-mono ml-3 ">
                          {moment(data?.createdAt).format("DD MMM YYYY HH:mm")}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-5">
                        <div className="col-span-1 text-base font-bold capitalize flex lg:justify-end ">
                          montant :{" "}
                        </div>
                        <div className="col-span-3 text-sm font-mono ml-3 ">
                          {data?.amount_paid} {data?.devise} /{" "}
                          {data?.day_active} jrs
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-5">
                        <div className="col-span-1 text-base font-bold capitalize flex lg:justify-end ">
                          Contenu :{" "}
                        </div>
                        <div className="col-span-3 text-sm font-mono ml-3 ">
                          {data?.publication?.title}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-5">
                        <div className="col-span-1 text-base font-bold capitalize flex lg:justify-end ">
                          Activé par :{" "}
                        </div>
                        <div className="col-span-3 text-sm font-mono ml-3 ">
                          {data?.adminId !== null ? data?.adminId : "vide"}
                        </div>
                      </div>

                      <div className="grid grid-cols-1 sm:grid-cols-5">
                        <div className="col-span-1 text-base font-bold capitalize flex lg:justify-end ">
                          référence:{" "}
                        </div>
                        <div className="col-span-3 text-sm font-mono ml-3 bg-slate-900 text-yellow-300 rounded-lg px-2 ">
                          {data.financial_transaction}
                        </div>
                      </div>

                      <div className="grid grid-cols-1 sm:grid-cols-5">
                        <div className="col-span-1 text-base font-bold capitalize flex lg:justify-end ">
                          réf-Lagui:{" "}
                        </div>
                        <div className="col-span-3 text-sm font-mono ml-3 bg-slate-900 text-yellow-300 rounded-lg px-5 ">
                          {data.operator}
                        </div>
                      </div>
                      {data.email && (
                        <div className="grid grid-cols-1 sm:grid-cols-5">
                          <div className="col-span-1 text-base font-bold capitalize flex lg:justify-end ">
                            Contact :
                          </div>
                          <div className="col-span-3 text-sm font-mono ml-3 bg-slate-900 text-yellow-300 rounded-lg px-5 ">
                            {data.email}
                          </div>
                        </div>
                      )}
                      {data.phone && (
                        <div className="grid grid-cols-1 sm:grid-cols-5">
                          <div className="col-span-1 text-base font-bold capitalize flex lg:justify-end ">
                            Téléphone :
                          </div>
                          <div className="col-span-3 text-sm font-mono ml-3 bg-slate-900 text-yellow-300 rounded-lg px-5 ">
                            {data.phone}
                          </div>
                        </div>
                      )}
                      {data.operator === "orange money" &&
                        !!data.client.phone && (
                          <div className="grid grid-cols-1 sm:grid-cols-5">
                            <div className="col-span-1 text-base font-bold capitalize flex lg:justify-end ">
                              Téléphone :
                            </div>
                            <div className="col-span-3 text-sm font-mono ml-3 bg-slate-900 text-yellow-300 rounded-lg px-5 ">
                              {data?.client.indicatif} {data?.client?.phone}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  {isEditable && (
                    <div className="m-4 mx-auto grid grid-cols-8 gap-5 border-2 border-orange-500 p-3 rounded-lg">
                      <div className="col-span-3">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nouveau Prix
                        </label>
                        <div className="mt-1">
                          <input
                            id="label"
                            name="label"
                            type="text"
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              handleUpdateBill("amount_paid", e.target.value);
                            }}
                            value={dataToSend.amount_paid}
                          />
                        </div>
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nombre de jours
                        </label>
                        <div className="mt-1">
                          <input
                            id="label"
                            name="label"
                            type="number"
                            value={dataToSend.day_active}
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              handleUpdateBill("day_active", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-6 col-span-2">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-2 sm:text-sm"
                          onClick={sendUpdate}
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/*  Submit */}
                <div className="mt-5 flex gap-5">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-2 sm:text-sm"
                    onClick={() => setIsEditable(true)}
                  >
                    Modifier
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                    onClick={deleteBill}
                  >
                    Supprimer
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
};

export default ModalDetailPaiement;
