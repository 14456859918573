import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "moment/locale/fr";

import { countries } from "../../fakeDatas/mokDatas";
import { debounce } from "lodash";
import Pagination from "../shared/Pagination";
import Loader from "../shared/Loader";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../redux/store/authStore";
import { deletePaiement } from "../../api/paiementsApi";
import ModalDetailPaiement from "../Modals/ModalDetailPaiement";

let PageSize = 30;

const TablePaiements = ({
  open,
  setOpen,
  setPaiements,
  paiements,
  loading,
  allPaiements,
}) => {
  const [message, setMessage] = useState("");
  const [dataSelected, setDataSelected] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [formUpdate, setFormUpdate] = useState({
    code: dataSelected.code,
    display_name: dataSelected.display_name,
    // status: dataSelected.status,
  });

  const currentUser = useSelector(selectCurrentUser);

  function handleChange(item, value) {
    setForm({
      ...form,
      [item]: value,
    });
  }

  console.log("paiements", paiements);

  function actifOrInactif(el) {
    if (el === "Actif") {
      return true;
    } else if (el === "Inactif") {
      return false;
    }
  }

  function handleChangeUpdate(item, value) {
    console.log("value", item, value);
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  //  GET ALL

  // CREATE
  const onCreate = async () => {
    await addPaiement(form)
      .then((res) => {
        setMessage(
          <p className="bg-red-500 w-full text-sm text-center rounded-full p-2 shadow-lg shadow-red-300">
            🚀 Paiement créer avec succès !
          </p>
        );
        setTimeout(() => {
          setOpen(false);
        }, [2000]);

        allPaiements();
      })
      .catch((err) => console.log("err", err));
  };

  // DELETE
  const onDelete = async () => {
    console.log("dataSelected.id", dataSelected.id);

    await deletePaiement(dataSelected.id).then((res) => {
      allPaiements();

      setMessage(
        <p className="bg-green-500 w-full text-sm text-center rounded-full p-2 shadow-lg shadow-green-300">
          ⚠️ Paiement supprimée avec succès !
        </p>
      );
      setTimeout(() => {
        setOpenDelete(false);
      }, [2000]);
    });
  };

  function handleSearch(event) {
    setQuery(event.target.value);
  }

  const [query, setQuery] = useState("");

  // Retarde L'appel lors de la saisie de la recherche !
  const debouncedSearch = useCallback(debounce(handleSearch, 2000), []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return paiements?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, paiements]);

  return (
    <div className="pt-5">
      <div className="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div>
          <label className="ml-3 text-gray-500" htmlFor="">
            Recherche :
          </label>
        </div>

        <input
          className="border-5 pl-3 w-full lg:w-3/5 h-12 rounded-lg shadow-md mb-5 border-b-2 border-red-500"
          type="text"
          placeholder="Saisir une Information pour la recherche"
          onChange={debouncedSearch}
        />
      </div>
      {!currentTableData && clients === undefined ? (
        <Loader />
      ) : (
        <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
          {loading ? (
            <Loader />
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date de Paiement
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Client
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Opérateur
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Montant
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Publications
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>

              {query !== "" ? (
                <tbody className="bg-white divide-y divide-gray-200">
                  {paiements
                    ?.filter((val) => {
                      if (val === "") {
                        return val;
                      } else if (
                        val?.operator
                          ?.toLowerCase()
                          ?.includes(query?.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val?.id
                          ?.toString()
                          .toLowerCase()
                          ?.includes(query?.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val?.publication?.title
                          ?.toLowerCase()
                          ?.includes(query?.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val?.client?.firstName
                          ?.toLowerCase()
                          ?.includes(query?.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val?.client?.firstName
                          ?.toLowerCase()
                          ?.includes(query?.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val?.client?.identifiant
                          ?.toLowerCase()
                          ?.includes(query?.toLowerCase())
                      )
                        return null;
                    })
                    .map((data) => (
                      <tr key={data.id}>
                        <td className="px-3 py-2 whitespace-nowrap ">
                          <div className="flex items-center gap-2">
                            <div
                              onClick={() => {
                                setOpen(false);
                                setOpenDetail(true);
                                setMessage(null);
                                setDataSelected(data);
                              }}
                              className={`w-12 tetxt-center flex justify-center  text-white text-xs ${
                                data.status ? "bg-teal-500" : "bg-red-500"
                              }  rounded-xl py-0.5`}
                            >
                              #{data.id}
                            </div>
                            <div
                              className={`h-3 w-3 bg-${
                                data.status ? "green" : "red"
                              }-500 text-white rounded-full`}
                            />

                            <p className="text-sm underline-offset-1 ">
                              {moment(data.createdAt).format("DD.MM.YYYY")}
                            </p>
                          </div>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap">
                          {data?.clientId === null ? (
                            <p className="text-red-500"> Supprimé</p>
                          ) : (
                            <p className="text-sm rounded-full text-left m-1 truncate ">
                              {data?.client?.firstName +
                                " " +
                                data?.client?.lastName}
                            </p>
                          )}
                        </td>

                        <td className="px-2 py-4 whitespace-nowrap">
                          <p className="text-sm rounded-full text-left m-1 truncate ">
                            {data.operator}
                          </p>
                        </td>

                        <td className="px-3 py-4 whitespace-nowrap flex">
                          <div>
                            <p className="text-sm rounded-full text-left m-1 truncate ">
                              {data?.amount_paid?.toLocaleString()}{" "}
                              {data.devise}
                            </p>
                          </div>
                          <div>
                            <p className="text-xs rounded-full text-left m-1 truncate ">
                              {data?.day_active} jrs
                            </p>
                          </div>
                        </td>

                        <td className="px-3 py-4 whitespace-nowrap">
                          <p className="text-sm rounded-full text-left m-1 truncate ">
                            {data?.publication?.title}
                          </p>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                          <button className="inline-flex items-center p-1.5 ml-3 border border-red rounded-full shadow-sm text-white bg-red-600 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => {
                                setOpen(false);
                                setOpenDelete(true);
                                setMessage(null);
                                setDataSelected({
                                  id: data?.id,
                                  amount_paid: data?.amount_paid,
                                  financial_transaction:
                                    data?.financial_transaction,
                                  // status: data?.status,
                                });
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentTableData !== undefined &&
                    currentTableData?.map((data, index) => (
                      <tr key={data.id}>
                        <td className="px-3 py-2 whitespace-nowrap  ">
                          <div className="flex items-center gap-2">
                            <div
                              onClick={() => {
                                setOpen(false);
                                setOpenDetail(true);
                                setMessage(null);
                                setDataSelected(data);
                              }}
                              className={`w-12 tetxt-center flex justify-center  text-white text-xs ${
                                data.status ? "bg-teal-500" : "bg-red-500"
                              }  rounded-xl py-0.5 cursor-pointer hover:underline underline-offset-1`}
                            >
                              #{data.id}
                            </div>
                            <div
                              className={`h-3 w-3 bg-${
                                data.status ? "green" : "red"
                              }-500 text-white rounded-full`}
                            />

                            <p className="text-sm underline-offset-1 ">
                              {moment(data.createdAt).format("DD.MM.YYYY")}
                            </p>
                          </div>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap">
                          {data?.clientId === null ? (
                            <p className="text-red-500"> Supprimé</p>
                          ) : (
                            <p className="text-sm rounded-full text-left m-1 truncate ">
                              {data?.client?.firstName +
                                " " +
                                data?.client?.lastName}
                            </p>
                          )}
                        </td>

                        <td className="px-2 py-4 whitespace-nowrap">
                          <p className="text-sm rounded-full text-left m-1 truncate ">
                            {data.operator}
                          </p>
                        </td>

                        <td className="px-3 py-4 whitespace-nowrap flex">
                          <div>
                            <p className="text-sm rounded-full text-left m-1 truncate ">
                              {data?.amount_paid?.toLocaleString()}{" "}
                              {data.devise}
                            </p>
                          </div>
                          <div>
                            <p className="text-xs rounded-full text-left m-1 truncate ">
                              /{data?.day_active} jrs
                            </p>
                          </div>
                        </td>

                        <td className="px-3 py-4 whitespace-nowrap">
                          <p className="text-sm rounded-full text-left m-1 truncate ">
                            {data?.publication?.title}
                          </p>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                          <button
                            disabled={currentUser.roles !== "admin"}
                            className="inline-flex items-center p-1.5 ml-3 border border-red rounded-full shadow-sm text-white bg-red-600 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => {
                                setOpen(false);
                                setOpenDelete(true);
                                setMessage(null);
                                setDataSelected({
                                  id: data?.id,
                                  financial_transaction:
                                    data?.financial_transaction,
                                  amount_paid: data?.amount_paid,
                                  // status: data?.status,
                                });
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          )}
        </div>
      )}

      {currentTableData && query === "" ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={paiements?.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      ) : null}

      {/* Modal Delete */}
      <ModalDetailPaiement
        data={dataSelected}
        open={openDetail}
        setOpen={setOpenDetail}
        allPaiements={allPaiements}
      />
      {/* Modal Delete */}
      <Transition.Root show={openDelete} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenDelete}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900 "
                    >
                      <div>
                        Supprimer le Paiement {dataSelected?.amount_paid} ?
                      </div>
                      <div className="font-light text-red-400  ">
                        {dataSelected?.financial_transaction} - #{" "}
                        {dataSelected.id}
                      </div>
                    </Dialog.Title>

                    <div className="mt-5 w-full border-t border-gray-400 font-light text-center">
                      Attention toute suppression entraînera une irréversibilité
                      totale
                    </div>
                  </div>
                </div>
                {message ? (
                  message
                ) : (
                  <>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => onDelete()}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpenDelete(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default TablePaiements;
