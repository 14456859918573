import { createSlice } from "@reduxjs/toolkit";
import { authAction } from "../action/authAction";
const initialState = {
  user: null,
  isAuthenticated: false,
  token: null,
};
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    resetUserAdmin: (state, payload) => {
      state = initialState;
      localStorage.removeItem("token");
      return state;
    },

    setUserAdmin: (state, payload) => {
      state.user = payload.payload.user;
      state.isAuthenticated = true;
      state.token = payload.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authAction.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.user;
        state.isAuthenticated = true;
        state.token = payload.token;
        return state;
      }
    );
    builder.addMatcher(
      authAction.endpoints.logout.matchFulfilled,
      (state, {}) => {
        state = initialState;
        return state;
      }
    );
  },
});

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const isAuthenticated = (state) => state.auth.isAuthenticated;
export const token = (state) => state.auth.token;

export const { resetUserAdmin, setUserAdmin } = authSlice.actions;
