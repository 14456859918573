import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ResetPassword from "../pages/client/ResetPassword";
import ForgotPasswordPage from "../pages/client/ForgotPasswordPage";
import LoginPage from "../pages/LoginPage";

const ClientRoute = () => {
  return (
    <div>
      <Router>
        {/* Navbar here  */}
        <Switch>
          <Route component={ForgotPasswordPage} path="/forgot-password" />
          <Route
            exact
            component={ResetPassword}
            path="/reset-password/:token"
          />
          <Route exact component={LoginPage} path="/login" />
          <Route exact component={LoginPage} path="*" />
        </Switch>
        {/* Footer here  */}
      </Router>
    </div>
  );
};

export default ClientRoute;
