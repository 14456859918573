import axios from "axios";
import "../axios";

export const addCategorie = async (form) => {
  let data = await axios.post("/categories", form);
  return data;
};

export const getAllCategories = async () => {
  let data = await axios.get("/categories");
  return data;
};

export const updateCategorie = async (id, form) => {
  let data = await axios.patch(`/categories/${id}`, form);
  return data;
};

export const getCategorieInfo = async (id) => {
  let data = await axios.get(`/categories/${id}`);
  return data;
};
export const deleteCategorie = async (id) => {
  let data = await axios.delete(`/categories/${id}`);
  return data;
};
