import React, { useEffect, useMemo, useState } from "react";

import TitleComponent from "../../shared/TitleComponent";
import {
  faCar,
  faCartFlatbed,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import GenericTableContenu from "../../components/TableComponent/GenericTableContenu";
import GenericButton from "../../components/shared/GenericButton";
import GenericLink from "../../components/shared/GenericLink";
import GenericTableCategories from "../../components/TableComponent/GenericTableCategories";

import { fakeCategories } from "../../fakeDatas/fakeDatas";
import { getAllType } from "../../api/typesApi";
import { useDispatch } from "react-redux";
import { setCategoryRedux } from "../../redux/store/appStore";
import GenericTableGenders from "../../components/TableComponent/GenericTableGenders.jsx";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";

export default function GenderPage() {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [genders, setGenders] = useState();
  const dispatch = useDispatch();

  const allGenders = async () => {
    await getAllType()
      .then((res) => {
        setGenders(res.data.data);
      })
      .catch((err) => console.log("Error", err));
  };

  useEffect(() => {
    allGenders();
  }, []);

  return (
    <TemplateHeaderContainter title=" Liste des Genres">
      {/* <TitleComponent title={`Genres`} icon={faCar} color={"text-blue-700"} /> */}
      <div className="px-5">
        {/* <div className="text-md pl-5">
          <h3> Liste des Genres</h3>
        </div> */}
        {/* name, bgColor, textcolor, handleClick */}
        {/* <GenericButton
          textcolor={"white"}
          bgColor={"teal"}
          name="+ Genre"
          handleClick={() => setOpen(true)}
        /> */}

        <button
          onClick={() => setOpen(true)}
          className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Ajouter Genre
        </button>
        <div className="p-1">
          <div className=" grid lg:grid-cols-3 sm:grid-cols-1  mx-auto ">
            {/* Replace with your content */}
          </div>

          <GenericTableGenders
            genders={genders}
            setGenders={setGenders}
            open={open}
            setOpen={setOpen}
          />
        </div>
      </div>
    </TemplateHeaderContainter>
  );
}
