import React from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

const ModalUpdateClient = ({
  formUpdate,
  openUpdate,
  setOpenUpdate,
  handleUpdateClient,
  onUpdateClient,
}) => {
  return (
    <div>
      <Transition.Root show={openUpdate} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenUpdate}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-2 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full align-bottom mx-3 bg-white rounded-lg px-3 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium text-center leading-10 text-gray-900"
                    >
                      Mise a jour de{" "}
                      <span className=""> {formUpdate.firstName}</span>
                    </Dialog.Title>
                    <div className="mt-5 grid md:grid-cols-2 sm:grid-cols-1 gap-2">
                      <div className="" id="firstName">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Prénom
                        </label>
                        <div className="mt-1">
                          <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            defaultValue={formUpdate.firstName}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              handleUpdateClient("firstName", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="" id="lastName">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom
                        </label>
                        <div className="mt-1">
                          <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            defaultValue={formUpdate.lastName}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              handleUpdateClient("lastName", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-span-1 " id="phone">
                        <label
                          htmlFor="phone"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Téléphone
                        </label>
                        <div className="mt-1">
                          <input
                            id="phone"
                            name="phone"
                            type="tel"
                            defaultValue={formUpdate.phone}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              handleUpdateClient("phone", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-span-1 " id="email">
                        <label
                          htmlFor="email"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            defaultValue={formUpdate.email}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              handleUpdateClient("email", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="" id="password">
                        <label
                          htmlFor="password"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            name="password"
                            type="text"
                            // value={passwordTemp && passwordTemp}
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              handleUpdateClient("password", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {/* 
                  <div className="col-span-1 w-full" id="button">
                    <button
                      className="px-3 py-2 bg-blue-400 hover:bg-blue-500 rounded-lg mx-auto mt-3 text-white"
                      handleClick={genPassword}
                    >
                      Generer Mot de passe
                    </button>
                  </div> */}

                      <div className="" id="status">
                        <div className="flex items-center h-5">
                          <input
                            onChange={(e) => {
                              console.log("e.target.checked", e.target.checked);
                              handleUpdateClient("status", e.target.checked);
                            }}
                            id="status"
                            name="status"
                            type="checkbox"
                            defaultChecked={formUpdate.status}
                            className="mt-9 focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-8 text-sm">
                          <label
                            htmlFor="status"
                            className=" text-left font-medium text-gray-700"
                          >
                            Compte Actif ?
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  Submit */}
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                    onClick={() => onUpdateClient(formUpdate.id)}
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenUpdate(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ModalUpdateClient;
