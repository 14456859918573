import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { addPaiement, addPaiementAdmin } from "../../api/paiementsApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/store/authStore";

const ModalActivate = ({ data, movies, open, setOpen }) => {
  const [amountPaid, setAmountPaid] = useState(null);
  const [devise, setDevise] = useState();
  const [dayActive, setDayActive] = useState();
  const [selectedMovie, setSelectedMovie] = useState({});
  const [defaultMovie, setDefaultMovie] = useState({});
  const [dataToSend, setDataToSend] = useState();
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  function handleUpdate(item, value) {
    if (currentUser.id)
      setDataToSend({
        ...dataToSend,
        [item]: value,
        financial_transaction:
          "admin-" + currentUser.firstName + "." + Date.now(),
        partenaire_id: null,
        operator: "Agent" + "-" + currentUser.firstName,
        reference: "ref",
        start_date: Date.now(),
        clientId: data.id,
        status: true,
        adminId: currentUser.id,
      });
  }

  let dateNow = moment(Date.now());

  const sendData = async () => {
    dataToSend.devise = devise;
    dataToSend.day_active = dayActive;
    dataToSend.clientId = data.id;

    // console.log("dataToSendSAVE", dataToSend);

    setLoading(true);

    await addPaiementAdmin(dataToSend)
      .then((res) => {
        setOpen(false);
        setDataToSend({ title: movies[0]?.title });
        setDayActive();
        toast.success("🚀 Paiement enregistré !!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-2 pb-40 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full align-bottom mx-3 bg-white rounded-lg px-3 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="-mt-2 text-sm italic bg-slate-900 text-emerald-300 w-3/4 mx-auto text-center rounded-lg">
                    #{currentUser.id} {currentUser.firstName}
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold text-center leading-10 text-gray-900"
                    >
                      Activation pour
                      <span className=""> {data.firstName}</span>
                    </Dialog.Title>
                    <div className="mt-5 grid md:grid-cols-6 grid-cols-1 gap-3">
                      <div
                        className="md:col-span-3 col-span-6"
                        id="select-contenu"
                      >
                        <label
                          htmlFor="type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Film a activer :
                        </label>
                        <select
                          id="type"
                          name="type"
                          className="mt-1 block w-full  rounded-md border-gray-300 py-3 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          value={selectedMovie.name}
                          onChange={(event) => {
                            const selectedMovieTitle = event.target.value;
                            const _selectedMovie = movies.find(
                              (movie) => movie.title === selectedMovieTitle
                            );
                            setSelectedMovie({
                              title: _selectedMovie?.title,
                              publicationId: _selectedMovie?.id,
                              day_active: _selectedMovie?.planTarifaire?.days,
                              rate_cfa: _selectedMovie?.planTarifaire.rate_cfa,
                              rate_gn: _selectedMovie?.planTarifaire.rate_gn,
                              rate_eur: _selectedMovie?.planTarifaire.rate_eur,
                            });

                            setDayActive(_selectedMovie?.planTarifaire?.days);
                            handleUpdate("publicationId", _selectedMovie.id);
                          }}
                        >
                          <option>Choisir le Film</option>
                          {movies.map((movie) => (
                            <option key={movie.id}>{movie.title}</option>
                          ))}
                        </select>
                      </div>

                      <div className="md:col-span-3 col-span-6" id="day_active">
                        <label
                          htmlFor="day_active"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nombre de jours activé
                        </label>
                        <div className="mt-1">
                          <input
                            id="day_active"
                            name="day_active"
                            type="number"
                            // defaultValue={selectedMovie.day_active}
                            value={dayActive && Number(dayActive)}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(event) => {
                              setDayActive(event.target.value);
                              // handleUpdate(
                              //   "day_active",
                              //   Number(event.target.value)
                              // );
                            }}
                          />
                        </div>
                      </div>

                      {selectedMovie && (
                        <div
                          className="md:col-span-3 col-span-6"
                          id="select-contenu"
                        >
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Montant :
                          </label>
                          <select
                            id="price"
                            name="price"
                            className="mt-1 block w-full  rounded-md border-gray-300 py-3 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            value={dataToSend && dataToSend.amount_paid}
                            onChange={(event) => {
                              const selectedValue = event.target.value;
                              handleUpdate(
                                "amount_paid",
                                Number(selectedValue)
                              );
                              const selectedOption =
                                event.target.options[
                                  event.target.selectedIndex
                                ];

                              const _devise = selectedOption.dataset.devise;
                              console.log("_devise", _devise);
                              setDevise(_devise);
                            }}
                          >
                            <option>Choisir le montant</option>
                            <option
                              value={selectedMovie?.rate_cfa}
                              data-devise="CFA"
                            >
                              {selectedMovie.rate_cfa} CFA
                            </option>
                            <option
                              value={selectedMovie?.rate_gn}
                              data-devise="GNF"
                            >
                              {selectedMovie?.rate_gn} GNF
                            </option>
                            <option
                              value={selectedMovie?.rate_eur}
                              data-devise="EUR"
                            >
                              {selectedMovie?.rate_eur} EUR
                            </option>
                          </select>
                        </div>
                      )}
                      {/* <div>
                      <div>
                        <label
                          htmlFor="type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Devise :
                        </label>
                      </div>
                      <div className="mt-3"> {devise} </div>
                    </div> */}
                    </div>
                  </div>
                </div>

                {/*  Submit */}
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                    onClick={sendData}
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
};

export default ModalActivate;
