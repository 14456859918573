import {
  ADMIN_PICTO,
  PARTENAIRE_PICTO,
  PRODUCTEUR_PICTO,
  SUPERVISEUR_PICTO,
} from "../shared/pictos";

export function SelectColorStatus(status) {
  switch (status) {
    case "admin":
      return "bg-orange-600";

    case "producteur":
      return "bg-sky-500 ";

    case "superviseur":
      return "bg-red-400 ";

    case "partenaire":
      return "bg-teal-400 ";

    default:
      break;
  }
}

export function SelectImage(status) {
  switch (status) {
    case "admin":
      return ADMIN_PICTO;

    case "producteur":
      return PRODUCTEUR_PICTO;

    case "superviseur":
      return SUPERVISEUR_PICTO;

    case "partenaire":
      return PARTENAIRE_PICTO;

    default:
      break;
  }
}
