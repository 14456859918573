import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import Pagination from "../shared/Pagination";
import { debounce } from "lodash";
import { TrashIcon } from "@heroicons/react/solid";
import GenericModalDelete from "../shared/GenericModalDelete";
import { selectCurrentUser } from "../../redux/store/authStore";
import { useSelector } from "react-redux";
import {
  separateThousandsMonetary,
  separateThousandsMonetaryEuros,
} from "../../utils/separateThousand";

let PageSize = 15;

const GenericTableContenu = ({ datas, onDelete, setIdMovie, idMovie }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const currentUser = useSelector(selectCurrentUser);

  function handleSearch(event) {
    setQuery(event.target.value);
  }

  const [query, setQuery] = useState("");

  // Retarde L'appel lors de la saisie de la recherche !
  const debouncedSearch = useCallback(debounce(handleSearch, 800), []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return datas?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, datas]);

  return (
    <div className="pt-0 overflow-auto">
      <div className="mt-5 max-w-7xl mx-auto px-2 sm:px-6 md:px-2">
        <div>
          <label className="ml-3 text-gray-500" htmlFor="">
            Recherche :
          </label>
        </div>

        <input
          className="border-5 pl-3 w-full lg:w-3/5 h-12 rounded-lg shadow-md mb-5 border-b-2 border-red-500"
          type="text"
          placeholder="Saisir une Information pour la recherche"
          onChange={debouncedSearch}
        />
      </div>
      <table className="min-w-full divide-y divide-gray-200 lg:visible">
        <thead className="bg-gray-100">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Titre
            </th>

            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Prix
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Catégorie
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Langue
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Producteur
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Détails
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {datas.map((data) => (
            <tr key={data?.id}>
              <td className="px-3 py-2 whitespace-nowrap">
                <div className="grid  grid-rows-2">
                  <div className="row-span-2">
                    <img
                      className="h-14 aspect-square object-contain mx-auto "
                      src={data?.image_banner}
                      alt={"🎬"}
                    />
                  </div>
                  <Link to={`/admin/detail-publication/${data?.id}`}>
                    <p className="text-sm  text-center lg:text-base hover:cursor-pointer  underline-offset-4 underline decoration-sky-500/30 ">
                      {" "}
                      {data?.title}
                    </p>
                  </Link>
                </div>
              </td>

              <td className="px-6 py-2 whitespace-nowrap">
                <p className="text-sm px-2 font-extrabold  text-orange-600 ring-orange-500 ring-1 rounded-full text-center m-1 ">
                  {separateThousandsMonetary(data?.planTarifaire.rate_gn)} GNF
                </p>
                <p className="text-sm font-extrabold  text-red-500 ring-red-500 ring-1  rounded-full text-center m-1 ">
                  {separateThousandsMonetaryEuros(data?.planTarifaire.rate_eur)}{" "}
                  EUR
                </p>
                <p className="text-sm font-extrabold  text-teal-500 ring-teal-500 ring-1  rounded-full text-center m-1 ">
                  {separateThousandsMonetary(data?.planTarifaire.rate_cfa)} CFA
                </p>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 text-center">
                  {data?.category.label}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 text-left">
                  {data?.language}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 text-left capitalize">
                  {data?.admin?.display_name}
                </div>
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                {currentUser.roles !== "admin" ||
                currentUser.roles !== "superviseur" ? null : (
                  <>
                    <Link
                      to={`/admin/detail-publication/${data?.id}`}
                      type="button"
                      className="inline-flex items-center p-1.5 border border-red rounded-full shadow-sm text-white text-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <EyeIcon className="h-5 w-5" aria-hidden="true" />
                    </Link>

                    <button
                      onClick={() => {
                        setIdMovie({ id: data?.id, title: data?.title });
                        setOpenModalDelete(true);
                      }}
                      type="button"
                      className="inline-flex items-center p-1.5 border border-red rounded-full shadow-sm text-white bg-red-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <TrashIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <GenericModalDelete
        openDelete={openModalDelete}
        setOpenDelete={setOpenModalDelete}
        message={`Etes vous sûre de vouloir supprimer le Film ${idMovie?.title}`}
        data={idMovie?.title}
        onDelete={onDelete}
      />

      {currentTableData && query === "" ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={datas?.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      ) : null}
    </div>
  );
};

export default GenericTableContenu;
