import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faTrash,
  faUpload,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

export default function ModalAddEpisode({
  open,
  setOpen,
  formEpisode,
  addField,
  addSaveEpisode,
}) {
  const cancelButtonRef = useRef(null);

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom lg:w-1/2 bg-white rounded-lg px-2 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900 "
                    >
                      Ajout d'un nouvel Episode
                    </Dialog.Title>

                    <div className="mt-3 w-full border-t border-gray-400"></div>
                    <div className="mt-5 grid grid-cols-1 gap-4">
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Titre de L'Épisode
                        </label>
                        <div className="mt-1">
                          <input
                            id="label"
                            name="label"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              addField("label", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="">
                        <label
                          htmlFor="dexcrition"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Description (facultatif)
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="description"
                            rows={5}
                            name="description"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              addField("description", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="link"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom du fichier avec extension .mp4 par exemple
                        </label>
                        <div className="mt-1">
                          <input
                            id="link"
                            name="link"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              addField("video_link", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="link"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom du fichier vers. Mobile avec extension .mp4 par
                          exemple
                        </label>
                        <div className="mt-1">
                          <input
                            id="link"
                            name="link"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              addField("video_link_for_mobile", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex border-t-2 mt-2 pt-2 gap-4 justify-center items-center  ">
                        <div className="">
                          <label
                            htmlFor="order"
                            className="text-left block text-sm font-medium text-gray-700"
                          >
                            Ordre de l'Épisode
                          </label>
                          <div className="mt-1">
                            <input
                              id="order"
                              name="order"
                              type="number"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                              onChange={(e) => {
                                addField(
                                  "order_number",
                                  Number(e.target.value)
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label
                            htmlFor="duration"
                            className="text-left block text-sm font-medium text-gray-700 ml-2"
                          >
                            Durée
                          </label>
                          <div className="flex ml-1">
                            <input
                              id="duration"
                              name="duration"
                              type="text"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                              defaultValue={formEpisode.duration}
                              onChange={(e) => {
                                addField("duration", e.target.value);
                              }}
                            />
                            <span className="inline-flex items-center rounded-r-md border  border-gray-300 bg-gray-50 px-3 text-gray-700 sm:text-sm">
                              minutes
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                      onClick={addSaveEpisode}
                    >
                      Valider
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Annuler
                    </button>
                  </div>
                </>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
