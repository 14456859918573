import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import GenericModalsEdit from "../Modals/GenericModalsEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "moment/locale/fr";
import {
  addDevise,
  updateDevise,
  getAllDevises,
  deleteDevise,
} from "../../api/devisesApi";
import { countries } from "../../fakeDatas/mokDatas";
import { debounce } from "lodash";
import Pagination from "../shared/Pagination";

// import countriesList from "countries-list";

let PageSize = 15;

const TableDevise = ({ data, setDevises, open, setOpen }) => {
  const [message, setMessage] = useState("");
  const [dataSelected, setDataSelected] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [allCountries, setAllCotcountries] = useState(countries);

  const [currentPage, setCurrentPage] = useState(1);

  const cancelButtonRef = useRef(null);

  const [form, setForm] = useState({
    code: "",
    display_name: "",
    flag: "",
    // status: true,
  });

  // console.log("countriesList", countriesList.countries);

  const [formUpdate, setFormUpdate] = useState({
    code: dataSelected.code,
    display_name: dataSelected.display_name,
    // status: dataSelected.status,
  });

  function handleChange(item, value) {
    setForm({
      ...form,
      [item]: value,
    });
  }

  function actifOrInactif(el) {
    if (el === "Actif") {
      return true;
    } else if (el === "Inactif") {
      return false;
    }
  }

  function handleChangeUpdate(item, value) {
    console.log("value", item, value);
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  //  GET ALL
  const allCategories = async () => {
    await getAllDevises()
      .then((res) => setDevises(res.data.data))
      .catch((err) => console.log("Error", err));
  };

  // CREATE
  const onCreate = async () => {
    await addDevise(form)
      .then((res) => {
        setMessage(
          <p className="bg-red-500 w-full text-sm text-center rounded-full p-2 shadow-lg shadow-red-300">
            🚀 Devise créer avec succès !
          </p>
        );
        setTimeout(() => {
          setOpen(false);
        }, [2000]);

        allCategories();
      })
      .catch((err) => console.log("err", err));
  };

  // DELETE
  const onDelete = async () => {
    await deleteDevise(dataSelected.id).then((res) => {
      allCategories();

      setMessage(
        <p className="bg-green-500 w-full text-sm text-center rounded-full p-2 shadow-lg shadow-green-300">
          ⚠️ Devise supprimée avec succès !
        </p>
      );
      setTimeout(() => {
        setOpenDelete(false);
      }, [2000]);
    });
  };

  // UPDATE
  const onUpdate = async () => {
    await updateDevise(dataSelected.id, formUpdate).then((res) => {
      allCategories();
      setMessage(
        <p className="bg-green-500 w-full text-sm text-center rounded-full p-2 shadow-lg shadow-green-300">
          ⚠️ Devise Mis a jour avec succès !
        </p>
      );
      setTimeout(() => {
        setOpenUpdate(false);
      }, [2000]);
    });
  };

  function handleSearch(event) {
    setQuery(event.target.value);
  }

  const [query, setQuery] = useState("");

  // Retarde L'appel lors de la saisie de la recherche !
  const debouncedSearch = useCallback(debounce(handleSearch, 800), []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return countries?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, countries]);

  return (
    <div className="pt-5">
      <div className="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div>
          <label className="ml-3 text-gray-500" htmlFor="">
            Recherche :
          </label>
        </div>

        <input
          className="border-5 pl-3 w-full lg:w-3/5 h-12 rounded-lg shadow-md mb-5 border-b-2 border-red-500"
          type="text"
          placeholder="Saisir une Information pour la recherche"
          onChange={debouncedSearch}
        />
      </div>
      {!currentTableData && clients === undefined ? (
        <Loader />
      ) : (
        <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Pays
                </th>

                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  indicatif
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>

            {query !== "" ? (
              <tbody className="bg-white divide-y divide-gray-200">
                {countries
                  ?.filter((val) => {
                    if (val === "") {
                      return val;
                    } else if (
                      val?.name
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.native
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.phone
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.currency
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.continent
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.email?.toLowerCase()?.includes(query?.toLowerCase())
                    ) {
                      return val;
                    }
                    return null;
                  })
                  .map((data) => (
                    <tr key={data.name}>
                      <td className="px-3 py-2 whitespace-nowrap ">
                        <div className="flex items-center gap-2">
                          <div className="text-sm text-gray-900 ">
                            <div className="h-3 w-3 bg-green-500 text-white rounded-full"></div>
                          </div>
                          <p className="text-sm underline-offset-1 ">
                            {data.emoji + " " + data.native}
                          </p>
                        </div>
                      </td>

                      <td className="px-2 py-4 whitespace-nowrap">
                        <p className="text-sm rounded-full text-left m-1 truncate ">
                          {data.continent} - {data.currency}
                        </p>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <p className="text-sm rounded-full text-left m-1 truncate ">
                          {data.phone}
                        </p>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                        <button className="inline-flex items-center p-1.5 border border-red rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => {
                              setOpenUpdate(true);
                              setMessage(null);
                              setDataSelected({
                                id: data?.id,
                                code: data?.code,
                                display_name: data?.display_name,
                                // status: data?.status,
                              });
                              console.log("dataSelected", dataSelected);
                            }}
                          />
                        </button>
                        <button className="inline-flex items-center p-1.5 ml-3 border border-red rounded-full shadow-sm text-white bg-red-600 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => {
                              setOpen(false);
                              setOpenDelete(true);
                              setMessage(null);
                              setDataSelected({
                                id: data?.id,
                                code: data?.label,
                                display_name: data?.display_name,
                                // status: data?.status,
                              });
                            }}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            ) : (
              <tbody className="bg-white divide-y divide-gray-200">
                {currentTableData
                  ?.filter((val) => {
                    if (val === "") {
                      return val;
                    } else if (
                      val?.name
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.native
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.phone
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.currency
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.continent
                        ?.toLowerCase()
                        ?.includes(query?.toLowerCase()) ||
                      val?.email?.toLowerCase()?.includes(query?.toLowerCase())
                    ) {
                      return val;
                    }
                    return null;
                  })
                  .map((data) => (
                    <tr key={data.name}>
                      <td className="px-3 py-2 whitespace-nowrap ">
                        <div className="flex items-center gap-2">
                          <div className="text-sm text-gray-900 ">
                            <div className="h-3 w-3 bg-green-500 text-white rounded-full"></div>
                          </div>
                          <p className="text-sm underline-offset-1 ">
                            {data.emoji + " " + data.native}
                          </p>
                        </div>
                      </td>

                      <td className="px-2 py-4 whitespace-nowrap">
                        <p className="text-sm rounded-full text-left m-1 truncate ">
                          {data.continent} - {data.currency}
                        </p>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <p className="text-sm rounded-full text-left m-1 truncate ">
                          {data.phone}
                        </p>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                        <button className="inline-flex items-center p-1.5 border border-red rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => {
                              setOpenUpdate(true);
                              setMessage(null);
                              setDataSelected({
                                id: data?.id,
                                code: data?.code,
                                display_name: data?.display_name,
                                // status: data?.status,
                              });
                              console.log("dataSelected", dataSelected);
                            }}
                          />
                        </button>
                        <button className="inline-flex items-center p-1.5 ml-3 border border-red rounded-full shadow-sm text-white bg-red-600 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => {
                              setOpen(false);
                              setOpenDelete(true);
                              setMessage(null);
                              setDataSelected({
                                id: data?.id,
                                code: data?.label,
                                display_name: data?.display_name,
                                // status: data?.status,
                              });
                            }}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
            <tbody className="bg-white divide-y divide-gray-200">
              {currentTableData
                ?.filter((val) => {
                  if (val === "") {
                    return val;
                  } else if (
                    val?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
                    val?.native
                      ?.toLowerCase()
                      ?.includes(query?.toLowerCase()) ||
                    val?.phone?.toLowerCase()?.includes(query?.toLowerCase()) ||
                    val?.currency
                      ?.toLowerCase()
                      ?.includes(query?.toLowerCase()) ||
                    val?.continent
                      ?.toLowerCase()
                      ?.includes(query?.toLowerCase()) ||
                    val?.email?.toLowerCase()?.includes(query?.toLowerCase())
                  ) {
                    return val;
                  }
                  return null;
                })
                .map((data) => (
                  <tr key={data.name}>
                    <td className="px-3 py-2 whitespace-nowrap ">
                      <div className="flex items-center gap-2">
                        <div className="text-sm text-gray-900 ">
                          {data.status === true ? (
                            <div className="h-3 w-3 bg-green-500 text-white rounded-full"></div>
                          ) : (
                            <div className=" h-3 w-3 bg-red-500 text-white rounded-full"></div>
                          )}
                        </div>
                        <p className="text-sm underline-offset-1 ">
                          {data.emoji + " " + data.native}
                        </p>
                      </div>
                    </td>

                    <td className="px-2 py-4 whitespace-nowrap">
                      <p className="text-sm rounded-full text-left m-1 truncate ">
                        {data.continent} - {data.currency}
                      </p>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <p className="text-sm rounded-full text-left m-1 truncate ">
                        {data.phone}
                      </p>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                      <button className="inline-flex items-center p-1.5 border border-red rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={() => {
                            setOpenUpdate(true);
                            setMessage(null);
                            setDataSelected({
                              id: data?.id,
                              code: data?.code,
                              display_name: data?.display_name,
                              // status: data?.status,
                            });
                            console.log("dataSelected", dataSelected);
                          }}
                        />
                      </button>
                      <button className="inline-flex items-center p-1.5 ml-3 border border-red rounded-full shadow-sm text-white bg-red-600 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => {
                            setOpen(false);
                            setOpenDelete(true);
                            setMessage(null);
                            setDataSelected({
                              id: data?.id,
                              code: data?.label,
                              display_name: data?.display_name,
                              // status: data?.status,
                            });
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {currentTableData && query === "" ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={countries?.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      ) : null}

      {/* Modals Create */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900 uppercase"
                    >
                      Ajout d'une nouvelle Devise
                    </Dialog.Title>

                    <div className="mt-3 w-full border-t border-gray-400"></div>
                    <div className="mt-5 grid grid-cols-1 gap-4">
                      {/* Nom */}
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Code de la Devise
                        </label>
                        <div className="mt-1">
                          <input
                            id="code"
                            name="code"
                            type="text"
                            required
                            className="appearance-none uppercase block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            // defaultValue={categories?.code}
                            onChange={(e) => {
                              handleChange("code", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {/* Description */}
                      <div className="" id="display_name">
                        <label
                          htmlFor="display_name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Affichage
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="display_name"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            // defaultValue={categories?.display_name}
                            onChange={(e) => {
                              handleChange("display_name", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="location"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Status
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="mt-1 block w-full rounded-md border-gray-300 py-3 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          // defaultValue={
                          //   categories.status === true ? "Actif" : "Inactif"
                          // }
                          onChange={(e) => {
                            handleChange(
                              "status",
                              actifOrInactif(e.target.value)
                            );
                            console.log(e.target.value);
                          }}
                        >
                          <option>Actif</option>
                          <option>Inactif</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {message ? (
                  message
                ) : (
                  <>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => onCreate()}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Modals Update */}
      <Transition.Root show={openUpdate} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenUpdate}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900 uppercase"
                    >
                      Modification d'une Devise
                    </Dialog.Title>

                    <div className="mt-3 w-full border-t border-gray-400"></div>
                    <div className="mt-5 grid grid-cols-1 gap-4">
                      {/* Nom */}
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom de la Devise
                        </label>
                        <div className="mt-1">
                          <input
                            id="label"
                            name="label"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            defaultValue={dataSelected?.label}
                            onChange={(e) => {
                              handleChangeUpdate("label", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {/* Description */}
                      <div className="">
                        <label
                          htmlFor="lastName"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="description"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            defaultValue={dataSelected?.description}
                            onChange={(e) => {
                              handleChangeUpdate("description", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="location"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Status
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="mt-1 block w-full rounded-md border-gray-300 py-3 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          defaultValue={
                            dataSelected?.status === true ? "Actif" : "Inactif"
                          }
                          onChange={(e) => {
                            handleChangeUpdate(
                              "status",
                              actifOrInactif(e.target.value)
                            );
                            console.log(e.target.value);
                          }}
                        >
                          <option>Actif</option>
                          <option>Inactif</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {message ? (
                  message
                ) : (
                  <>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => onUpdate()}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpenUpdate(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Modal Delete */}
      <Transition.Root show={openDelete} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenDelete}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900 uppercase"
                    >
                      Supprimer la catégorie {dataSelected?.label} ?
                    </Dialog.Title>

                    <div className="mt-3 w-full border-t border-gray-400"></div>
                  </div>
                </div>
                {message ? (
                  message
                ) : (
                  <>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => onDelete()}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpenDelete(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default TableDevise;
