import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const ModalShowPublication = ({ episode, open, setOpen }) => {
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-2/4  sm:w-full sm:p-6">
                <div>
                  <div className="mt-2 sm:mt-2">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900 uppercase"
                    >
                      Detail Episode
                    </Dialog.Title>

                    <div className="mt-3 w-full border-t border-gray-400"></div>
                    <div className=" mt-2">
                      <p>
                        <span className="font-medium text-gray-900">
                          TITRE :{" "}
                        </span>
                        <span className="text-base text-gray-600 mb-1">
                          {episode.label}
                        </span>
                      </p>
                      <p className="mt-3">
                        <span className="font-medium text-gray-900">
                          DESCRIPTION :{" "}
                        </span>
                        <br />
                        <span className="text-base text-gray-600 mb-1">
                          {" "}
                          {episode.description}{" "}
                        </span>
                      </p>
                      <div className="mt-3 w-full border-t border-gray-400">
                        Aperçu de la vidéo
                      </div>
                      <div className="border border-1 mt-1">
                        <video width="100%" height="240" controls>
                          <source src={episode.video_link} type="video/mp4" />
                          <source src={episode.video_link} type="video/ogg" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end m-1">
                  <div className="m-2">
                    <div />
                    <button
                      type="button"
                      className="m-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-cyan-500 text-base font-medium text-gray-700 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ModalShowPublication;
