import React, { useCallback, useMemo, useState } from "react";

import moment from "moment";
import "moment/locale/fr";
import { debounce } from "lodash";

import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../redux/store/authStore";
import { deletePaiement } from "../../api/paiementsApi";

let PageSize = 30;

const RevenuTable = ({
  paiements,
  data,
  user,
  separate,
  title,
  currency,
  commision,
}) => {
  const [message, setMessage] = useState("");
  const [dataSelected, setDataSelected] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [formUpdate, setFormUpdate] = useState({
    code: dataSelected.code,
    display_name: dataSelected.display_name,
    // status: dataSelected.status,
  });

  const { roles } = useSelector(selectCurrentUser);
  console.log("roles", roles);

  function handleChange(item, value) {
    setForm({
      ...form,
      [item]: value,
    });
  }

  function actifOrInactif(el) {
    if (el === "Actif") {
      return true;
    } else if (el === "Inactif") {
      return false;
    }
  }

  function handleChangeUpdate(item, value) {
    console.log("value", item, value);
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  function handleSearch(event) {
    setQuery(event.target.value);
  }

  const [query, setQuery] = useState("");

  // Retarde L'appel lors de la saisie de la recherche !
  const debouncedSearch = useCallback(debounce(handleSearch, 800), []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return paiements?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, paiements]);

  return (
    <div className="flex flex-col  px-4 lg:px-10 border-2 border-emerald-400 mt-5 rounded-lg   ">
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 text-center">
            {title}
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-orange-500 sm:pl-0"
                  >
                    Date
                  </th>
                  {roles === "producteur" ? null : (
                    <th
                      scope="col"
                      class="whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-orange-500"
                    >
                      Total
                    </th>
                  )}
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-orange-500"
                  >
                    {user.firstName}
                  </th>
                  {user.roles !== "producteur" && (
                    <th
                      scope="col"
                      class="whitespace-nowrap  px-2 py-3.5 text-center text-sm font-semibold text-orange-500"
                    >
                      Transfert Lagui+
                    </th>
                  )}
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                {data.slice(-5).map((data, index) => (
                  <tr key={index}>
                    <td class="whitespace-nowrap py-2 pl-4 pr-3  font-light uppercase text-sm text-gray-500 sm:pl-0">
                      {data.date}
                    </td>
                    {roles === "producteur" ? null : (
                      <td class="whitespace-nowrap px-2 py-2 text-sm font-bold text-gray-900">
                        {separate(data.total)}{" "}
                        <span className="text-md text-slate-800 font-extralight">
                          {" "}
                          {currency}{" "}
                        </span>
                      </td>
                    )}
                    <td class="whitespace-nowrap px-2 py-2 text-sm italic font-bold text-gray-900">
                      {separate(data.total * (commision / 100))}{" "}
                      <span className="text-md text-slate-800 font-extralight">
                        {" "}
                        {currency}{" "}
                      </span>
                    </td>
                    {user.roles !== "producteur" && (
                      <td class="whitespace-nowrap px-2 py-2 text-sm italic font-bold text-gray-900">
                        {separate(data.total - data.total * (commision / 100))}
                        {"  "}
                        <span className="text-md text-slate-800 font-extralight">
                          {" "}
                          {currency}{" "}
                        </span>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenuTable;
