import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StatsPaiements({ stats, title, icon, colorIcon }) {
  return (
    <div className="p-2 mt-5">
      <h3 className="text-lg font-medium leading-6 text-gray-90 ml-5">
        {title}
      </h3>

      <dl className="mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pt-2 pb-8 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <p className="text-lg font-light  text-gray-900">
                <span className="text-4xl italic text-teal-600">
                  {item.stat}
                </span>
                <span className="ml-2"> {item.name}</span>
              </p>
              <p className="ml-12 truncate text-md font-medium text-gray-700"></p>
            </dt>
            <dd className="ml-12 flex items-baseline pb-2 lg:pb-6 sm:pb-2">
              {/* <p className="text-xl font-semibold text-gray-900">
                {item.stat}
                <span className="text-sm italic text-gray-600"></span>
              </p> */}

              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-3 sm:px-6">
                <div className="text-sm">
                  <Link
                    to="/admin/paiement"
                    className="font-medium text-orange-500 hover:text-orange-600"
                  >
                    Voir Détails
                    <span className="sr-only"> {item.name} stats</span>
                  </Link>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
