import React from "react";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";

const InfosPage = () => {
  return (
    <div>
      <TemplateHeaderContainter title={"Informations"}>
        <div className="max-w-7xl mx-auto px-4 sm:px-4 md:px-8 selection:bg-cyan-500 selection:text-white">
          <div className="font-light">
            Retrouvez dans cette section toutes les informations et bonnes
            pratiques pour la gestion et la compréhension de votre Panel. Nous
            publierons régulèrement des informations dans cette section.
            N'hésitez pas à consulter pour rester informer des nouveautés
          </div>

          {/* <div className="max-w-7xl p-8 text-slate-800 ">
            <details className="p-6 rounded-lg" open>
              <summary className="text-sm leading-6 text-slate-900 font-semibold select-none">
                Comment nous joindre ?
              </summary>
              <div className="mt-3 text-sm leading-6 text-amber-700 ">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Tempore alias aperiam natus exercitationem voluptates sunt
                  neque voluptas, provident similique quo voluptatibus sapiente,
                  nostrum aut cumque eaque temporibus quod pariatur? Nulla,
                  sapiente quas! Necessitatibus suscipit harum cumque aspernatur
                  eius consequuntur delectus eaque, provident quisquam.
                  Voluptatem, laboriosam repellat sequi perferendis debitis
                  maiores at nam cum vero magni, numquam mollitia veritatis
                  pariatur tempore, atque distinctio veniam ratione. Molestiae
                  eligendi nam enim unde corrupti doloribus rem? Eveniet
                  repellendus iure minus tempore suscipit officiis facilis
                  aspernatur rerum, at adipisci aperiam dicta perspiciatis fuga
                  nostrum, explicabo modi! Assumenda quam eos eaque eum deleniti
                  delectus tenetur rem temporibus vero eligendi? Error nulla
                  eius doloribus amet est.
                </p>
              </div>
            </details>
          </div>
          <article className="prose prose-neutral lg:prose-xl mt-10 font-light">
            <h1 className="text-decoration-slice bg-gradient-to-r from-indigo-600 to-pink-500 text-white px-2 w-4/6 rounded-lg">
              Bientôt disponible
            </h1>
            <p className="font-light text-justify ">
              For years parents have espoused the health benefits of eating
              garlic bread with cheese to their children, with the food earning
              such an iconic status in our culture that kids will often dress up
              as warm, cheesy loaf for Halloween.
            </p>
            <p>
              But a recent study shows that the celebrated appetizer may be
              linked to a series of rabies cases springing up around the
              country.
            </p>
          </article> */}
        </div>
      </TemplateHeaderContainter>
    </div>
  );
};

export default InfosPage;
