import React, { useState, useEffect, useMemo, useCallback } from "react";

import Loader from "../../components/shared/Loader";

import { useSelector } from "react-redux";
import Pagination from "../../components/shared/Pagination";
import { debounce } from "lodash";
import { FaDollarSign, FaEye, FaKey, FaLock, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import {
  addClient,
  deleteClient,
  getAllClients,
  updateClient,
  updatePasswordClient,
} from "../../api/clientsApi";
import { Link, useHistory } from "react-router-dom";
import GenericModalDelete from "../../components/shared/GenericModalDelete";
import ModalAddClient from "../../components/Modals/ModalAddClient";
import ModalUpdateClient from "../../components/Modals/ModalUpdateClient";
import ModalActivate from "../../components/Modals/ModalActivate";
import ModalBlockClient from "../../components/Modals/ModalBlockClient";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import ModalMessageInfo from "../../components/Modals/ModalMessageInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { getAllPublications } from "../../api/publicationApi";
import ModalConfirmation from "../../components/Modals/ModalConfirmation";

let PageSize = 50;

export default function ClientsPage() {
  let history = useHistory();
  const roles = useSelector((state) => state.auth?.user?.roles);

  // Manage Modals States
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openActivate, setOpenActivate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMessageInfo, setOpenMessageInfo] = useState(false);
  const [openBlockClient, setOpenBlockClient] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [newPassword, setNewPassword] = useState("123456");

  const [clients, setClients] = useState();
  const [movies, setMovies] = useState([]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [query, setQuery] = useState("");
  const [checked, setChecked] = useState(true);
  const [passwordTemp, setPasswordTemp] = useState("");
  const [infoUser, setInfoUser] = useState({
    id: "",
    name: "",
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    status: true,
  });

  const [formActive, setFormActive] = useState({
    nombre_de_jours: null,
    montant_paye: null,
    operateur: null,
    devise: "",
  });

  const [formUpdate, setFormUpdate] = useState({
    id: form.id,
    firstName: form.firstName,
    lastName: form.lastName,
    email: form.email,
    phone: form.phone,
    password: form.password,
    status: form.status,
  });

  const [formClient, setFormClient] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    status: "",
  });

  function createClient(item, value) {
    setFormClient({
      ...formClient,
      [item]: value,
    });
  }

  function genPassword() {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 4;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
      let randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    setPasswordTemp(password);
    console.log("password", password);
    setFormClient({ ...formClient, password: passwordTemp });
  }

  const getDataClients = async () => {
    const data = await getAllClients();
    // setClients(fakeClients);
    setClients(data.data.data);
    console.log(data.data.data);
    setLoading(false);
  };

  const getDataMovies = async () => {
    const data = await getAllPublications();
    setMovies(data.data.data);
    console.log(data.data.data);
    setLoading(false);
  };

  const onCreate = async () => {
    formClient.status = checked;

    await addClient(formClient)
      .then((res) => {
        setMessage("Client ajouter avec succès");
        getDataClients();
        setOpen(false);
        setLoading(false);
      })
      .then((err) => console.log("err", err));
  };

  const onblock = async () => {
    let _tempForm = null;
    if (form.status === true) {
      _tempForm = { ...form, status: false };
    } else {
      _tempForm = { ...form, status: true };
    }
    await updateClient(form.id, _tempForm).then((res) => {
      setMessage("Client Bloquer avec succès");
      getDataClients();
      setOpenBlockClient(false);
    });
  };

  useEffect(() => {
    getDataClients();
    getDataMovies();
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return clients?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, clients]);

  function handleSearch(event) {
    setQuery(event.target.value);
  }

  const onDelete = async () => {
    setLoading(true);
    await deleteClient(infoUser.id)
      .then(() => {
        setOpenDelete(false);
        getDataClients();
        setTimeout(() => {
          setLoading(false);
          history.push("/admin/clients");
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  function handleUpdateClient(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  function handleActive(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  // Update Client
  const onUpdateClient = async () => {
    setLoading(true);
    await updateClient(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  // Update Password
  const ResetPassword = async () => {
    setLoading(true);

    await updatePasswordClient(form.id, { password: newPassword })
      .then((res) => {
        toast.success(<div> 🔐 Nouveau Mot de passe {newPassword}</div>, {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setOpenConfirmation(false);

        getDataClients();

        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  // Activate Client for movie
  const onActivate = async () => {
    console.log("Activate ;-)");
    setLoading(true);
    await updateClient(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  // Retarde L'appel lors de la saisie de la recherche !
  const debouncedSearch = useCallback(debounce(handleSearch, 4000), []);

  return (
    <TemplateHeaderContainter title={`Clients`} dataCount={clients?.length}>
      <div className="">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* <h1 className="mb-2 text-2xl font-semibold text-gray-900">
          Liste des Clients{" "}
          <span className="italic text-md font-light text-gray-500">
            ( {clients?.length} )
          </span>
        </h1> */}

          <button
            disabled={roles !== "admin" || roles !== "superviseur"}
            onClick={() => setOpen(true)}
            type="button"
            className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Ajouter un Client
          </button>
        </div>

        <div className="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div>
            <label className="ml-3 text-gray-500" htmlFor="">
              Recherche :
            </label>
          </div>

          <input
            className="border-5 pl-3 w-full lg:w-3/5 h-12 rounded-lg shadow-md mb-5 border-b-2 border-red-500"
            type="text"
            placeholder="Saisir une Information pour la recherche"
            onChange={debouncedSearch}
          />

          {(!currentTableData && clients === undefined) || loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            #ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Nom et Email
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      {query !== "" ? (
                        <tbody className="bg-white divide-y divide-gray-200">
                          {clients
                            ?.filter((val) => {
                              if (val === "") {
                                return val;
                              } else if (
                                val?.phone
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase()) ||
                                val?.firstName
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase()) ||
                                val?.lastName
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase()) ||
                                val?.email
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase()) ||
                                val?.identifiant
                                  ?.toLowerCase()
                                  ?.includes(query?.toLowerCase())
                              ) {
                                return val;
                              }
                              return null;
                            })
                            .map((user, index) => (
                              <tr key={index}>
                                <td className="px-5 py-1 whitespace-nowrap relative flex justify-center align-middle mt-5">
                                  <div className="w-22 uppercase text-sm bg-teal-500 text-white px-1.5 py-1 rounded-full">
                                    {user.identifiant}
                                  </div>
                                  <span className="inline-flex items-center p-1.5 ml-2 rounded-full text-xs font-medium bg-blue-500 ">
                                    <Link
                                      to={`/admin/detail-client/${user.id}`}
                                      className
                                    >
                                      <FaEye className="text-white h-4 w-4" />
                                    </Link>
                                  </span>
                                </td>

                                <td className="px-5 py-1 whitespace-nowrap relative">
                                  <div className="flex items-center justify-center ">
                                    <div
                                      className="ml-8 w-full"
                                      onClick={() => {
                                        setFormUpdate({
                                          id: user.id,
                                          firstName: user.firstName,
                                          lastName: user.lastName,
                                          email: user.email,
                                          phone: user.phone,
                                          status: user.status,
                                        });
                                        setOpenUpdate(true);
                                      }}
                                    >
                                      <div className="text-sm font-medium text-gray-900 cursor-pointer">
                                        {user.firstName} {user.lastName}
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        {user.email}
                                      </div>
                                      {roles === "admin" ||
                                        (roles === "superviseur" && (
                                          <div className="text-sm text-gray-500">
                                            {user.indicatif
                                              ? user.indicatif +
                                                " " +
                                                user.phone
                                              : user.phone}
                                          </div>
                                        ))}
                                    </div>
                                  </div>

                                  <div className="grid grid-rows-2 absolute left-2 top-2 mt-2">
                                    <button
                                      type="button"
                                      className="inline-flex items-center p-1 border border-red rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                      onClick={() => {
                                        setForm(user);
                                        !user.status
                                          ? setOpenMessageInfo(true)
                                          : setOpenActivate(true);
                                      }}
                                    >
                                      <FaDollarSign
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </button>

                                    <div className="text-sm text-gray-900 text-center ml-2 mt-1">
                                      {user.status === true ? (
                                        <div className="bg-green-500 text-white rounded-full h-2 w-2" />
                                      ) : (
                                        <div className="bg-red-500 text-white rounded-full h-2 w-2" />
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap ">
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-violet-500 mr-3">
                                    <button
                                      className="hover:cursor-pointer "
                                      onClick={() => {
                                        setForm({
                                          id: user.id,
                                          firstName: user.firstName,
                                          lastName: user.lastName,
                                          email: user.email,
                                          address: user.address,
                                          phone: user.phone,
                                        });
                                        setOpenConfirmation(true);
                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaKey className="text-white h-4 w-4" />
                                    </button>
                                  </span>
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-orange-500 mr-3">
                                    <button
                                      disabled={
                                        roles !== "admin" ||
                                        roles !== "superviseur"
                                      }
                                      onClick={() => {
                                        setForm({
                                          id: user.id,
                                          firstName: user.firstName,
                                          lastName: user.lastName,
                                          email: user.email,
                                          address: user.address,
                                          phone: user.phone,
                                          city: user.city,
                                          postalCode: user.postalCode,
                                          status: user.status,
                                        });
                                        setOpenBlockClient(true);

                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaLock className="text-white h-4 w-4" />
                                    </button>
                                  </span>
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-blue-500 ">
                                    <Link
                                      to={`/admin/detail-client/${user.id}`}
                                      className
                                    >
                                      <FaEye className="text-white h-4 w-4" />
                                    </Link>
                                  </span>
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-red-500 ml-2">
                                    <button
                                      onClick={() => {
                                        setInfoUser({
                                          id: user.id,
                                          name: user.firstName,
                                        });
                                        setOpenDelete(true);
                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaTrash className="text-white h-4 w-4" />
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <tbody className="bg-white divide-y divide-gray-200">
                          {currentTableData !== undefined &&
                            currentTableData?.map((user, index) => (
                              <tr key={index}>
                                <td className="px-5 py-1 whitespace-nowrap relative flex justify-center align-middle mt-5">
                                  <div className="w-22 uppercase text-sm bg-teal-500 text-white px-1.5 py-1 rounded-full">
                                    {user.identifiant}
                                  </div>
                                  <span className="inline-flex items-center p-1.5 ml-2 rounded-full text-xs font-medium bg-blue-500 ">
                                    <Link
                                      to={`/admin/detail-client/${user.id}`}
                                      className
                                    >
                                      <FaEye className="text-white h-4 w-4" />
                                    </Link>
                                  </span>
                                </td>
                                <td className="px-5 py-1 whitespace-nowrap relative">
                                  <div className="flex items-center justify-center">
                                    <div
                                      className="ml-8 w-full"
                                      onClick={() => {
                                        setFormUpdate({
                                          id: user.id,
                                          firstName: user.firstName,
                                          lastName: user.lastName,
                                          email: user.email,
                                          phone: user.phone,
                                          status: user.status,
                                        });
                                        setOpenUpdate(true);
                                      }}
                                    >
                                      <div className="text-sm font-medium text-gray-900 cursor-pointer">
                                        {user.firstName} {user.lastName}
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        {user.email}
                                      </div>
                                      {roles === "admin" ||
                                        (roles === "superviseur" && (
                                          <div className="text-sm text-gray-500">
                                            {user.indicatif
                                              ? user.indicatif +
                                                " " +
                                                user.phone
                                              : user.phone}
                                          </div>
                                        ))}
                                    </div>
                                  </div>

                                  <div className="grid grid-rows-2 absolute left-2 top-2 mt-2">
                                    <button
                                      type="button"
                                      className="inline-flex items-center p-1 border border-red rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                      onClick={() => {
                                        setForm(user);
                                        !user.status
                                          ? setOpenMessageInfo(true)
                                          : setOpenActivate(true);
                                      }}
                                    >
                                      <FaDollarSign
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </button>

                                    <div className="text-sm text-gray-900 text-center ml-2 mt-1">
                                      {user.status === true ? (
                                        <div className="bg-green-500 text-white rounded-full h-2 w-2" />
                                      ) : (
                                        <div className="bg-red-500 text-white rounded-full h-2 w-2" />
                                      )}
                                    </div>
                                  </div>
                                </td>
                                {/* button */}
                                <td className="px-6 py-4 whitespace-nowrap ">
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-violet-500 mr-3">
                                    <button
                                      className="hover:cursor-pointer "
                                      onClick={() => {
                                        setForm({
                                          id: user.id,
                                          firstName: user.firstName,
                                          lastName: user.lastName,
                                          email: user.email,
                                          address: user.address,
                                          phone: user.phone,
                                        });
                                        setOpenConfirmation(true);
                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaKey className="text-white h-4 w-4" />
                                    </button>
                                  </span>
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-orange-500 mr-3">
                                    <button
                                      disabled={
                                        roles !== "admin" ||
                                        roles !== "superviseur"
                                      }
                                      onClick={() => {
                                        setForm({
                                          id: user.id,
                                          firstName: user.firstName,
                                          lastName: user.lastName,
                                          email: user.email,
                                          address: user.address,
                                          phone: user.phone,
                                          city: user.city,
                                          postalCode: user.postalCode,
                                          status: user.status,
                                        });
                                        setOpenBlockClient(true);
                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaLock className="text-white h-4 w-4" />
                                    </button>
                                  </span>
                                  <span className="inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-blue-500 ">
                                    <Link
                                      to={`/admin/detail-client/${user.id}`}
                                      className
                                    >
                                      <FaEye className="text-white h-4 w-4" />
                                    </Link>
                                  </span>

                                  <span
                                    className={`inline-flex items-center p-1.5 rounded-full text-xs font-medium bg-red-500 ml-2`}
                                  >
                                    <button
                                      disabled={roles !== "admin"}
                                      onClick={() => {
                                        setInfoUser({
                                          id: user.id,
                                          name: user.firstName,
                                        });
                                        setOpenDelete(true);
                                        console.log("infoUser", infoUser);
                                      }}
                                    >
                                      <FaTrash
                                        className={`text-white h-4 w-4 bg-red-500`}
                                      />
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {currentTableData && query === "" ? (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={clients?.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        ) : null}

        {/* Modal delete Client */}
        <GenericModalDelete
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          message={"Etes-vous sûre de vouloir supprimer ?"}
          data={infoUser.name}
          onDelete={onDelete}
        />

        {/* Modal add Client */}
        <ModalAddClient
          open={open}
          setOpen={setOpen}
          createClient={createClient}
          passwordTemp={passwordTemp}
          onCreate={onCreate}
          setChecked={setChecked}
        />

        {/* Modal Update Client */}
        <ModalUpdateClient
          formUpdate={formUpdate}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          handleUpdateClient={handleUpdateClient}
          onUpdateClient={onUpdateClient}
        />

        {/* Modal Activate Client */}
        <ModalActivate
          formActive={formActive}
          open={openActivate}
          setOpen={setOpenActivate}
          handleActive={handleActive}
          onActivate={onActivate}
          data={form}
          movies={movies}
        />

        {/* Modal Update Client */}
        <ModalMessageInfo
          open={openMessageInfo}
          setOpen={setOpenMessageInfo}
          data={form}
          picto={<FontAwesomeIcon icon={faBan} />}
        />

        <ModalConfirmation
          ResetPassword={ResetPassword}
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          data={form}
          setNewPassword={setNewPassword}
          newPassword={newPassword}
        />

        {/* Modal Block Client */}
        <ModalBlockClient
          formActive={formActive}
          open={openBlockClient}
          setOpen={setOpenBlockClient}
          onblock={onblock}
          data={form}
        />
      </div>
      <ToastContainer />
    </TemplateHeaderContainter>
  );
}
