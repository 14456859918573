import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import indicatifCountries from "../../datas/indicatifCountries.json";

const ModalCreateUser = ({
  openCreate,
  setOpenCreate,
  form,
  updateField,
  loading,
  onSubmit,
}) => {
  return (
    <div>
      <Transition.Root show={openCreate} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenCreate}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900"
                    >
                      Création d'un utilisateur
                    </Dialog.Title>
                    <div className="mt-5 grid lg:grid-cols-2 gap-4">
                      <div className="col-span-2 lg:col-span-1">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Prénom
                        </label>
                        <div className="mt-1">
                          <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.firstName}
                            onChange={(e) => {
                              updateField("firstName", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-span-2 lg:col-span-1">
                        <label
                          htmlFor="last-Name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom
                        </label>
                        <div className="mt-1">
                          <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.lastName}
                            onChange={(e) => {
                              updateField("lastName", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-span-2 lg:col-span-1">
                        <label
                          htmlFor="email"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom d'Affichage
                        </label>
                        <div className="mt-1">
                          <input
                            id="display_name"
                            name="display_name"
                            type="text"
                            required
                            value={form.display_name}
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              updateField("display_name", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-span-2 lg:col-span-1">
                        <label
                          htmlFor="email"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="mail"
                            required
                            value={form.email}
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              updateField("email", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {/* Password */}
                      <div className="col-span-2 lg:col-span-1">
                        <label
                          htmlFor=""
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            type="password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.password}
                            onChange={(e) => {
                              updateField("password", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-span-2">
                        <label
                          htmlFor="phone-number"
                          className="block text-sm font-medium leading-2 text-gray-900"
                        >
                          Téléphone
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 flex items-center">
                            <label htmlFor="indicatif" className="sr-only">
                              Indicatif
                            </label>
                            <select
                              onChange={(e) => {
                                updateField("indicatif", e.target.value);
                                console.log("e.target.value", e.target.value);
                              }}
                              id="indicatif"
                              name="indicatif"
                              autoComplete="indicatif"
                              className="h-full rounded-md border-0 bg-transparent text-sm py-0 pl-2 pr-0 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600"
                            >
                              <option value={null}> indicatif </option>
                              {indicatifCountries.map((item, index) => (
                                <option
                                  key={index}
                                  className="flex items-center"
                                  value={item.indicatif}
                                >
                                  {item.drapeau +
                                    " " +
                                    item.indicatif +
                                    " " +
                                    item.nom}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            onChange={(e) => {
                              updateField("telephone", e.target.value);
                            }}
                            maxLength={10}
                            type="tel"
                            name="phone-number"
                            id="phone-number"
                            className="block w-full px-2 border-gray-700 rounded-md border-0 py-1.5 pl-36 lg:mr-64 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                            placeholder="(555) 987-6543"
                          />
                        </div>
                      </div>

                      {/* Status */}
                      <div className="col-span-2 lg:col-span-1">
                        <label htmlFor="">
                          {form.status ? (
                            <span className="text-teal-600">
                              {" "}
                              Utilisateur Actif
                            </span>
                          ) : (
                            <span className="text-red-500">
                              {" "}
                              Utilisateur Inactif
                            </span>
                          )}
                        </label>
                        <input
                          onChange={(e) => {
                            updateField("status", e.target.checked);
                          }}
                          id="status"
                          name="status"
                          type="checkbox"
                          value={form.status}
                          className="ml-3 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                    </div>

                    {/* USER Rôles */}
                    <div className="grid grid-cols-1 lg:grid-cols-2  capitalize  ">
                      <select
                        className="h-full rounded-md border-0 bg-transparent py-2  text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset"
                        onChange={(e) => {
                          updateField("roles", e.target.value);
                        }}
                      >
                        <option value={null}>Choisir un Rôle</option>
                        <option value={"admin"}>Admin</option>
                        <option value={"producteur"}>Producteur</option>
                        <option value={"superviseur"}>Superviseur</option>
                        <option value={"partenaire"}>Partenaire</option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor=""
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Commentaire
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="comment"
                        type="text"
                        rows={4}
                        className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                        value={form.comment}
                        onChange={(e) => {
                          updateField("comment", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  {!loading ? (
                    <>
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                        onClick={() => onSubmit()}
                      >
                        Enregistrer
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => {
                          setForm({});
                          setOpenCreate(false);
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <p>... Loading</p>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ModalCreateUser;
