import React from "react";
import TemplateHeaderContainter from "../../../shared/TemplateHeaderContainter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/store/authStore";
import { SelectImage } from "../../../utils/process";
import UpdateUserPage from "./UpdateUserPage";

const UserDetailPage = () => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <TemplateHeaderContainter title={`Mon Profil`}>
      <div className="px-4 sm:px-6 md:px-8">
        <div className="my-2 max-w-7xl mx-auto ">
          <p className="font-light text-sm text-justify text-gray-500 italic">
            Menu pour mettre à jour son profil afin de recevoir les dernières
            notifications de mise a jour ou informations concernant votre compte
          </p>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-6">
          <div className="lg:col-span-2 col-span-2">
            <div className="mx-auto text-center p-2">
              <img
                className="inline-block h-40 w-40 rounded-full shadow-lg"
                src={SelectImage(currentUser.roles)}
                alt=""
              />
            </div>
          </div>
          <div className="lg:col-span-4 col-span-2">
            <UpdateUserPage currentUser={currentUser} />
          </div>
        </div>

        <div className="w-full border-t border-red-400 mt-5" />
        <h1 className="mt-5 text-xl">
          <span>
            <FontAwesomeIcon icon={faLock} className="text-red-500 mr-1" />
          </span>
          Historique de Navigation
        </h1>
        <p className="italic text-gray-400"> A venir ... </p>
      </div>
    </TemplateHeaderContainter>
  );
};

export default UserDetailPage;
