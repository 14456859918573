import axios from "axios";
import "../axios";

export const addDealer = async (form) => {
  let data = await axios.post("/dealers", form);
  return data;
};

export const getAllDealers = async () => {
  let data = await axios.get("/dealers");
  return data;
};
export const getStatsDealer = async (id) => {
  let data = await axios.get(`/dealers/stats/${id}`);
  return data;
};

export const getPaiementBydealer = async (id) => {
  let data = await axios.get(`paiement/paiement-admins/${id}`);
  return data;
};

// export const updateDealer = async (id, form) => {
//   let data = await axios.patch(`/dealers/${id}`, form);
//   return data;
// };

export const getDealerInfo = async (id) => {
  let data = await axios.get(`/dealers/${id}`);
  return data;
};

export const deleteDealer = async (id) => {
  let data = await axios.delete(`/dealers/${id}`);
  return data;
};
