import axios from "axios";
import "../axios";

export const addType = async (form) => {
  let data = await axios.post("/types", form);
  return data;
};

export const getAllType = async () => {
  let data = await axios.get("/types");
  return data;
};

export const updateType = async (id, form) => {
  let data = await axios.patch(`/types/${id}`, form);
  return data;
};

export const getTypeInfo = async (id) => {
  let data = await axios.get(`/types/${id}`);
  return data;
};
export const deleteType = async (id) => {
  let data = await axios.delete(`/types/${id}`);
  return data;
};
