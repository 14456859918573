import React, { useState, Fragment } from "react";
import { useLoginMutation } from "../redux/action/authAction";
import Loader from "../components/shared/Loader";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, EyeIcon } from "@heroicons/react/outline";
import logo from "../assets/logo_white.png";
import { useDispatch, useSelector } from "react-redux";
import { setUserAdmin } from "../redux/store/authStore";
import { login } from "../api/adminApi";
import { Link, useHistory } from "react-router-dom";
import { FaIcons } from "react-icons/fa";

export default function LoginPage() {
  const currentConnected = useSelector((state) => state);
  const history = useHistory();
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  console.log("User Connected", currentConnected);

  const [loading, setLoading] = useState(false);
  const [open1, setOpen1] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    setMessage(null);
    e.preventDefault();
    setLoading(true);

    try {
      await login(formData).then((res) => {
        console.log("res", res);
        history.push("/admin");
        dispatch(setUserAdmin(res.data.data));
        localStorage.setItem("token", res.data.data.token);
        setLoading(false);
        setMessage(res.data.message);
      });
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setMessage("Mot de passe ou Adresse mail Erronné");
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  };

  return (
    <div className="min-h-screen">
      {loading ? (
        <Loader />
      ) : (
        <div
          className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 min-h-screen"
          style={{
            backgroundImage: `url("https://github.com/ibou18/logo/blob/main/back.jpg?raw=true")`,
          }}
        >
          <div className="sm:mx-auto sm:w-full sm:max-w-md px-2 ">
            <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-teal-300">
              Connexion Administration
            </h2>
          </div>
          <div className="mt-8  sm:mx-auto sm:w-full sm:max-w-md px-5">
            <div className="bg-white py-8 px-5 rounded-lg sm:px-10 shadow-lg shadow-teal-500/60">
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      value={formData.email}
                      onChange={handleChange}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mot de passe
                  </label>
                  <div className="mt-1">
                    <input
                      value={formData.password}
                      onChange={handleChange}
                      id="password"
                      name="password"
                      type="password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center"></div>

                  <div className="text-sm">
                    <Link
                      to="/forgot-password"
                      className="font-medium text-current hover:text-hovers"
                    >
                      Mot de passe oublier ?
                    </Link>
                  </div>
                </div>
                <div>
                  <button
                    onClick={onSubmit}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                  >
                    Se connecter
                  </button>
                </div>
              </form>

              <div className="text-red-500 text-center ">
                {message ? message : ""}
              </div>
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500"> ... </span>
                  </div>
                </div>
                <p className="font-light text-center text-gray-600 ">
                  Si tu rencontres des problemes de connexion, merci de
                  contacter les administrateurs
                  <a
                    href="mailto:support@laguiplus.com"
                    className="text-gray-500 "
                  >
                    {" "}
                    support@laguiplus.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Error
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        L'email ou le mot de passe est incorrect
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen1(false)}
                  >
                    ok
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
