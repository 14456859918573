import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "../components/Layout/admin/Header";
import SideBar from "../components/Layout/admin/SideBar";

import {
  isAuthenticated,
  resetUserAdmin,
  selectCurrentUser,
} from "../redux/store/authStore";
import AdminRoute from "./userRoutes/AdminRoute";
import PartenaireRoute from "./userRoutes/PartenaireRoute";
import ProducteurRoute from "./userRoutes/ProducteurRoute";
import SuperviseurRoute from "./userRoutes/SuperviseurRoute";

let widthSideBar = 48;

const AdminRoutes = () => {
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isAuth = useSelector(isAuthenticated);
  const user = useSelector(selectCurrentUser);

  const ActiveRoutes = () => {
    console.log("user", user);
    if (user) {
      switch (user.roles) {
        case "admin":
          return <AdminRoute />;
          break;
        case "partenaire":
          return <PartenaireRoute />;
          break;
        case "producteur":
          return <ProducteurRoute />;
          break;
        case "superviseur":
          return <SuperviseurRoute />;
          break;

        default:
          break;
      }
    }
  };

  // const logout = () => {
  //   dispatch(resetUserAdmin(null));
  // };

  return (
    <div className="min-h-screen bg-gray-100">
      <Router>
        <SideBar
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          widthSideBar={widthSideBar}
        />
        <div className={`md:pl-48 flex flex-col`}>
          <Header setSidebarOpen={setSidebarOpen} />
          <main className="flex-1 pb-8 ">
            <ActiveRoutes />
          </main>
          {/* <FooterAdmin /> */}
        </div>
      </Router>
    </div>
  );
};

export default AdminRoutes;
