import React, { useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getActorInfo } from "../../api/actorsApi";
import { getAllPublications } from "../../api/publicationApi";
import GenericModalDelete from "../../components/shared/GenericModalDelete";
import Loader from "../../components/shared/Loader";
import moment from "moment";

import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";

import { ToastContainer, toast } from "react-toastify";
import ModalUpdateActor from "../../components/Modals/ModalUpdateActor";

const DetailActor = () => {
  let history = useHistory();
  let { id } = useParams();
  const [openUpdate, setOpenUpdate] = useState(false);

  const [acteur, setActeur] = useState({
    id: "",
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    status: "",
    createdAt: "",
  });

  const [openDelete, setOpenDelete] = useState(false);
  const [openModalActive, setOpenModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paiements, setPaiements] = useState([]);
  const [movies, setMovies] = useState([]);

  const [formUpdate, setFormUpdate] = useState({
    id: acteur?.id,
    email: acteur?.email,
    firstname: acteur?.firstname,
    lastname: acteur?.lastname,
    phone: acteur?.phone,
    status: acteur?.status,
  });

  const [form, setForm] = useState({
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    status: true,
  });

  function handleActive(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  function handleUpdateActeur(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  const getActeur = async () => {
    try {
      await getActorInfo(id)
        .then((res) => {
          setActeur(res.data.data);
          setLoading(false);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          history.push("/admin/not-found");
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getActeur();
    getDataMovies();
  }, []);

  const onDelete = async () => {
    setLoading(true);
    await deleteActeur(acteur?.id)
      .then(() => {
        setOpenDelete(false);
        setTimeout(() => {
          setLoading(false);
          history.push("/admin/acteurs");
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  const onUpdateActeur = async (id) => {
    setLoading(true);
    await updateActeur(acteur?.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getActeur();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  const getDataMovies = async () => {
    const data = await getAllPublications();
    setMovies(data.data.data);
    console.log(data.data.data);
    setLoading(false);
  };

  // Activate Acteur for movie
  const onActivate = async () => {
    setLoading(true);
    await updateActeur(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        toast.success("🚀 Activation avec succès !!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <TemplateHeaderContainter
      title={`Compte de ${acteur?.firstname}`}
      className=""
    >
      <div className=" px-4 sm:px-6 md:px-8">
        <Link
          to={"/admin/acteur"}
          type="button"
          className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Retour Liste des acteurs
        </Link>
      </div>
      {loading || !acteur || acteur === undefined ? (
        <Loader />
      ) : (
        <div className=" flex justify-center mx-2 lg:mx-10">
          <div className="w-full p-1">
            <div className="bg-white relative shadow-lg hover:shadow-xl transition duration-500 rounded-lg">
              <img
                className="rounded-full h-52 w-52 mx-auto object-cover "
                src={acteur.image}
                alt=""
              />
              <div className="py-3 px-2 rounded-lg bg-white">
                <div className="flex justify-center mb-4 ">
                  <h1 className="text-gray-700 uppercase font-bold text-lg md:text-2xl hover:text-gray-900 ">
                    {acteur?.firstname + " " + acteur?.lastname}
                  </h1>
                  <span className="tracking-wide ml-3">
                    {acteur?.status === true ? (
                      <p className=" px-3 rounded-xl uppercase bg-green-500 text-white font-normal text-sm w-24 h-7 flex justify-center pt-1">
                        Actif
                      </p>
                    ) : (
                      <p className=" px-3 rounded-xl uppercase bg-red-500 text-white font-normal text-sm w-24 h-7 flex justify-center pt-1">
                        bloqué
                      </p>
                    )}
                  </span>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 mt-3 mx-auto mb-5">
                  <button
                    disabled
                    onClick={() => {
                      setOpenUpdate(true);
                      setFormUpdate({
                        id: acteur?.id,
                        email: acteur?.email,
                        firstname: acteur?.firstname,
                        lastname: acteur?.lastname,
                        phone: acteur?.phone,
                        status: acteur?.status,
                      });

                      // setIsEditable(!isEditable);
                      // setStatusEdit(false); // False = Add step
                    }}
                    className="ml-2 py-1.5 px-2 bg-yellow-400 text-gray-800 font-bold rounded-lg shadow-md hover:shadow-lg transition duration-300 hover:bg-yellow-500"
                  >
                    Modifier
                  </button>
                  <button
                    disabled
                    onClick={() => setOpenDelete(true)}
                    className="ml-2 py-1.5 px-2 bg-red-400 text-white font-bold rounded-lg shadow-md hover:shadow-lg transition duration-300 hover:bg-red-500"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    <span className="ml-2"> Supprimer</span>
                  </button>
                </div>

                <div className="grid lg:grid-cols-2 grid-cols-1 ml-5 gap-y-0 lg:gap-3">
                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6">
                      Date Création :{" "}
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {moment(acteur?.createdAt).format("DD MMM YYYY")}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6">
                      Téléphone :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {acteur?.telephone}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6">
                      E-mail :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {acteur?.email}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6">
                      Genre :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {acteur?.gender}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6">
                      Pays :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {acteur?.country}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6">
                      Date de Naissance :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {moment(acteur?.birthday).format("DD MMM YYYY")}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6">
                      Description :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {acteur?.description}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6 ">
                      Facebook :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {acteur?.facebook_link
                        ? acteur?.facebook_link
                        : "aucune information"}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6 ">
                      Instagram :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {acteur?.instagram_link
                        ? acteur?.instagram_link
                        : "aucune information"}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6 ">
                      Youtube :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {acteur?.youtube_link
                        ? acteur?.youtube_link
                        : "aucune information"}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6 ">
                      Twitter | X :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {acteur?.twitter_link
                        ? acteur?.twitter_link
                        : "aucune information"}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-3/6 ">
                      Mis à jour :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {moment(acteur?.updatedAt).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="absolute -top-32 -right-5  md:top-4 md:right-4 py-2 px-2 bg-white rounded-lg">
                <span className="text-lg md:text-2xl"> </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <GenericModalDelete
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        message={"Etes-vous sûre de vouloir supprimer ?"}
        data={acteur?.firstname + " " + acteur?.lastname}
        onDelete={onDelete}
      />

      {/* Modal Update Acteur */}
      {acteur && (
        <ModalUpdateActor
          formUpdate={acteur}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          handleUpdateActeur={handleUpdateActeur}
          onUpdateActeur={onUpdateActeur}
        />
      )}

      <ToastContainer />
    </TemplateHeaderContainter>
  );
};

export default DetailActor;
