import React, { useState } from "react";
import moment from "moment";
import ModalDetailPaiement from "../Modals/ModalDetailPaiement";
import { useStateManager } from "react-select";

const PaiementTable = ({ paiements }) => {
  const [openDetail, setOpenDetail] = useState(false);
  const [paiementSelected, setPaiementSelected] = useState({});

  let EURO = new Intl.NumberFormat("fr", {
    style: "currency",
    currency: "EUR",
  });

  function color(value) {
    if (value === "Stripe") {
      return "bg-teal-400";
    } else if (value === "Paypal") {
      return "bg-blue-400";
    } else if (value === "Agent") {
      return "bg-orange-400";
    }
  }

  return (
    <div>
      <table className="min-w-full divide-y divide-gray-200 ">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              #
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Date
            </th>

            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Paiement
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Film
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Operateur
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {paiements.map((paiement, index) => (
            <tr>
              <td className="px-5 py-1 whitespace-nowrap relative">
                <div className="flex items-center justify-center gap-1 flex-col  ">
                  <div
                    className={`w-20 tetxt-center hover:cursor-pointer flex justify-center  text-white text-sm ${
                      paiement.status ? "bg-teal-500" : "bg-red-500"
                    }  rounded-xl py-0.5`}
                    onClick={() => {
                      setPaiementSelected(paiement);
                      setOpenDetail(true);
                    }}
                  >
                    #{paiement.id}
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-center">
                <div className="text-sm text-gray-900">
                  {moment(paiement.createdAt).format("DD MMM YYYY")}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-center">
                <div className="text-sm text-gray-900">
                  {paiement.amount_paid} {paiement.devise}
                  <span className="ml-2 italic font-light ">
                    - {paiement.day_active} jrs
                  </span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-center">
                <div className="text-sm text-gray-900">
                  {paiement.publication?.title}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-center">
                <span
                  className={`inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium ${color(
                    paiement.operator
                  )} text-gray-900`}
                >
                  {paiement.operator}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Modal Delete */}
      <ModalDetailPaiement
        data={paiementSelected}
        open={openDetail}
        setOpen={setOpenDetail}
      />
    </div>
  );
};

export default PaiementTable;
