import React, { useState, useEffect, Fragment, useMemo } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  TrashIcon,
  PencilAltIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import Loader from "../../components/shared/Loader";

import Pagination from "../../components/shared/Pagination";
import indicatifCountries from "../../datas/indicatifCountries.json";
import { Link } from "react-router-dom";

import {
  addUser,
  deleteUser,
  getAllUsers,
  updateUser,
} from "../../api/usersApi";
import { selectCurrentUser } from "../../redux/store/authStore";
import { useSelector } from "react-redux";

import { SelectColorStatus } from "../../utils/process";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalUpdateUser from "../../components/Modals/ModalUpdateUser";
import ModalCreateUser from "../../components/Modals/ModalCreateUser";
import { EyeIcon } from "@heroicons/react/solid";

let PageSize = 15;

export default function UserPage() {
  const currentUser = useSelector(selectCurrentUser);

  const [users, setUsers] = useState();
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    display_name: "",
    indicatif: "",
    email: "",
    address: "",
    country: "",
    telephone: "",
    password: "",
    status: true,
    roles: "",
    comment: "",
    commissionEUR: null,
  });

  function searchCountyByIndicatif(indicatifRecherche) {
    for (const pays of indicatifCountries) {
      if (pays.indicatif === indicatifRecherche) {
        return pays.nom;
      }
    }
    return "Indicatif non trouvé";
  }

  function searchFlagByCountry(countryRecherche) {
    for (const pays of indicatifCountries) {
      if (pays.nom === countryRecherche) {
        return pays.drapeau;
      }
    }
    return "";
  }

  const handleStatusChange = (e) => {
    const newStatuses = openUpdate ? form.roles : [...statuses];
    if (e.target.checked) {
      newStatuses.push(e.target.name);
    } else {
      newStatuses.splice(newStatuses.indexOf(e.target.name), 1);
    }
    setStatuses(newStatuses);
    setForm({ ...form, roles: newStatuses });
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const data = await getAllUsers();
    setUsers(data.data.data); // COMMENTED UNTIL I HAVE THE REAL
    setLoading(false);
  };

  function updateField(item, value) {
    setForm({
      ...form,
      [item]: value,
    });
  }

  const onSubmit = async () => {
    // setOpen(false);
    form.country = searchCountyByIndicatif(form.indicatif);
    form.commissionEUR = Number(form.commissionEUR);

    console.log("form", form);

    setLoading(true);
    if (form.id) {
      // UPDATE THE USER
      await updateUser(form.id, form)
        .then(() => {
          setForm({
            firstName: "",
            lastName: "",
            email: "",
            telephone: "",
            indicatif: "",
            status: null,
            country: "",
            password: "",
            roles: "",
            Comment: "",
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });

      setOpenUpdate(false);
      setLoading(false);
      setMessage(" Mise a jour avec succès");
      toast.success("🚀 Mise a jour enregistré !!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      getData();
    } else if (openCreate) {
      // CREATE THE USER
      await addUser(form)
        .then(() => {
          setOpenCreate(false);
          toast.success("🚀 Utilisateur enregistré !!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          setForm({
            firstName: "",
            lastName: "",
            email: "",
            telephone: "",
            indicatif: "",
            status: null,
            country: "",
            password: "",
            roles: [],
            Comment: "",
            commissionEUR: null,
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
      setMessage("Client crée avec succès");
      getData();
    }

    // setOpenSuccess(true);
  };

  const onDelete = async () => {
    setOpenDelete(false);
    setLoading(true);
    await deleteUser(id);
    setMessage("Client supprimer avec succès");
    setLoading(false);
    setOpenDelete(false);
    getData();
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return users?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, users]);

  return (
    <TemplateHeaderContainter
      title={" Liste des admins"}
      dataCount={users?.length}
    >
      <div className="">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* <h1 className="mb-3 text-2xl font-semibold text-gray-900">
            Liste des admins
          </h1> */}
          <button
            onClick={() => {
              setForm({
                firstName: "",
                lastName: "",
                email: "",
                telephone: "",
                indicatif: "",
                status: "",
                password: "",
                country: "",
                roles: [],
                comment: "",
                commissionEUR: null,
                commissionCFA: null,
                commissionGNF: null,
              });
              setOpenCreate(true);
            }}
            type="button"
            className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Ajouter un utilisateur
          </button>
        </div>

        <div className="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {currentTableData?.length === 0 || users === undefined ? (
            <Loader />
          ) : (
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Nom et Email
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Nom Affichage
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Tél.
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Pays
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Pays
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {currentTableData?.map((user) => (
                          <tr key={user.id}>
                            <td className="px-3 py-3 whitespace-nowrap">
                              <div className="flex items-center ">
                                <Link to={`/admin/detail-user/${user.id}`}>
                                  <EyeIcon
                                    className="h-6 w-6 text-blue-500"
                                    aria-hidden="true"
                                  />
                                </Link>
                                <div
                                  className="ml-2 w-full cursor-pointer "
                                  onClick={() => {
                                    setForm({
                                      id: user.id,
                                      firstName: user.firstName,
                                      lastName: user.lastName,
                                      email: user.email,
                                      display_name: user.display_name,
                                      telephone: user.telephone,
                                      status: user.status,
                                      country: user.country,
                                      roles: user.roles,
                                      comment: user.comment,
                                      indicatif: user.indicatif,
                                      commissionEUR: user.commissionEUR,
                                      commissionCFA: user.commissionCFA,
                                      commissionGNF: user.commissionGNF,
                                    });
                                    setId(user.id);
                                    setOpenUpdate(true);
                                  }}
                                >
                                  <div className="text-sm font-medium text-gray-900">
                                    {user.firstName} {user.lastName}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {user.email}
                                  </div>
                                  <div className=" grid grid-cols-1 w-1/2 ">
                                    {/* {user?.roles?.map((roleUser, index) => (
                                      <div
                                        key={index}
                                        className={` ${SelectColorStatus(
                                          roleUser
                                        )} rounded-full flex text-xs text-white justify-center m-0.5 uppercase`}
                                      >
                                        {roleUser}
                                      </div>
                                    ))} */}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                {user.display_name}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                {user.telephone}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800  ">
                                {searchFlagByCountry(user.country) +
                                  " " +
                                  user.country}
                              </span>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-mono">
                                {" "}
                                <div>
                                  {!!user.commissionEUR
                                    ? `EUR - ${user.commissionEUR} %`
                                    : ""}
                                </div>
                                <div>
                                  {!!user.commissionCFA
                                    ? `CFA - ${user.commissionCFA} %`
                                    : ""}
                                </div>
                                <div>
                                  {!!user.commissionGNF
                                    ? `GNF - ${user.commissionGNF} %`
                                    : ""}
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium ">
                              <div>
                                {currentUser?.id === user?.id ? null : (
                                  <button
                                    type="button"
                                    className="mr-2 inline-flex items-center p-1.5 border border-red rounded-full shadow-sm text-white bg-red-600 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                    onClick={() => {
                                      setId(user.id);
                                      setForm({
                                        lastName: user.lastName,
                                        firstName: user.firstName,
                                      });
                                      setOpenDelete(true);
                                    }}
                                  >
                                    {loading ? null : (
                                      <TrashIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="inline-flex items-center p-1.5 border border-red rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  onClick={() => {
                                    setForm({
                                      id: user.id,
                                      firstName: user.firstName,
                                      lastName: user.lastName,
                                      email: user.email,
                                      display_name: user.display_name,
                                      telephone: user.telephone,
                                      status: user.status,
                                      country: user.country,
                                      roles: user.roles,
                                      comment: user.comment,
                                      indicatif: user.indicatif,
                                      commissionEUR: user.commissionEUR,
                                      commissionCFA: user.commissionCFA,
                                      commissionGNF: user.commissionGNF,
                                    });
                                    setOpenUpdate(true);
                                  }}
                                >
                                  <PencilAltIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {currentTableData ? (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={users?.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        ) : null}
      </div>
      {/*  Modal Update User */}
      <ModalUpdateUser
        openUpdate={openUpdate}
        setOpenUpdate={setOpenUpdate}
        form={form}
        updateField={updateField}
        handleStatusChange={handleStatusChange}
        loading={loading}
        onSubmit={onSubmit}
        roles={currentUser?.roles}
      />

      {/* Modal Create User */}
      <ModalCreateUser
        openCreate={openCreate}
        setOpenCreate={setOpenCreate}
        updateField={updateField}
        form={form}
        loading={loading}
        onSubmit={onSubmit}
      />

      {/* Modal Success */}
      <Transition.Root show={openSuccess} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenSuccess}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Succès
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{message}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  {!loading ? (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => {
                        setOpenSuccess(false);
                        setOpenCreate(false);
                        setOpenUpdate(false);
                        setOpenDelete(false);
                        getData();
                      }}
                    >
                      ok
                    </button>
                  ) : (
                    <p> Chargement</p>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Modal Delete */}
      <Transition.Root show={openDelete} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenDelete}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Supprimer {form.firstName + " " + form.lastName}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Êtes-vous sûre de vouloir supprimer plus aucun retour
                        sera possible
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setOpenDelete(false);
                      onDelete();
                    }}
                  >
                    Supprimer
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDelete(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </TemplateHeaderContainter>
  );
}
